import { useCallback, useEffect } from 'react';
import { track as peloTrack } from '@peloton/analytics';
import type { AnalyticsEvent } from '../analytics';
import { toBaseProperties } from '../utils/webBaseProperties';

type TrackingFn = (event: AnalyticsEvent, properties?: object) => void;

const authBaseProperties = {
  appName: 'Auth',
};

export const useTrackingCallback = () => {
  const track: TrackingFn = useCallback((event, properties) => {
    peloTrack({
      event,
      properties: {
        ...toBaseProperties(),
        ...authBaseProperties,
        ...properties,
      },
    });
  }, []);

  return { track };
};

export const useTrackPageView: TrackingFn = (event, properties) => {
  const { track } = useTrackingCallback();
  useEffect(() => {
    track(event, properties);
  }, []);
};
