import React from 'react';

const ExpiredIcon: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5451 7.99497C25.6502 6.39686 23.3502 6.39686 22.4553 7.99497L6.57365 36.355C5.69876 37.9173 6.82799 39.8439 8.61858 39.8439H40.3818C42.1724 39.8439 43.3017 37.9173 42.4268 36.355L26.5451 7.99497ZM21.3101 7.35368C22.7062 4.86062 26.2942 4.86062 27.6903 7.35368L43.5719 35.7137C44.9368 38.1509 43.1752 41.1564 40.3818 41.1564H8.61858C5.82526 41.1564 4.06366 38.1509 5.42848 35.7137L21.3101 7.35368ZM25.6252 33.3752C25.6252 33.9965 25.1216 34.5002 24.5002 34.5002C23.8789 34.5002 23.3752 33.9965 23.3752 33.3752C23.3752 32.7539 23.8789 32.2502 24.5002 32.2502C25.1216 32.2502 25.6252 32.7539 25.6252 33.3752ZM23.1111 17.9961L23.9633 29.5016C23.9841 29.7827 24.2183 30.0002 24.5003 30.0002C24.7822 30.0002 25.0164 29.7827 25.0372 29.5016L25.8894 17.9961C25.9493 17.1884 25.3101 16.5002 24.5003 16.5002C23.6904 16.5002 23.0512 17.1884 23.1111 17.9961Z"
      fill="#FFE28C"
    />
  </svg>
);

export default ExpiredIcon;
