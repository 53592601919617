/// <reference path="../../../node_modules/@types/segment-analytics/index.d.ts" />

import { isDefined } from '@peloton/types';

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS | undefined;
  }
}

/**
 * Wait for all enabled destinations in Segment to load.
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#ready
 *
 * Caveat: This requires a valid write key, otherwise we attempt to load
 * `http://cdn.segment.com/analytics.js/v1//analytics.min.js` which fails and `analytics.ready`
 * never triggers the callback.
 */
export const analyticsReady = (maybeWindow?: Window) => {
  if (typeof window !== 'undefined' && isDefined(maybeWindow?.analytics)) {
    return new Promise<void>(resolve => {
      maybeWindow?.analytics?.ready(resolve);
    });
  } else {
    return Promise.resolve();
  }
};
