import React from 'react';

const EmailIcon: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.15625 12C6.15625 10.6824 7.18244 9.65625 8.5 9.65625H40.5C41.8176 9.65625 42.8438 10.6824 42.8438 12V12.6384L24.5501 24.1922C24.5196 24.2115 24.4806 24.2115 24.45 24.1922L6.15625 12.6383V12ZM4.84375 12.9995C4.84375 12.9994 4.84375 12.9994 4.84375 12.9993V12C4.84375 9.95756 6.45756 8.34375 8.5 8.34375H40.5C42.5424 8.34375 44.1562 9.95756 44.1562 12V12.9845C44.1565 12.9944 44.1565 13.0044 44.1562 13.0143V35.94C44.1562 37.9882 42.5565 39.6562 40.5 39.6562H8.5C6.44349 39.6562 4.84375 37.9882 4.84375 35.94V12.9995ZM42.8438 14.1907V35.94C42.8438 37.2918 41.8035 38.3438 40.5 38.3438H8.5C7.19651 38.3438 6.15625 37.2918 6.15625 35.94V14.1906L23.7492 25.3019C24.2079 25.5916 24.7923 25.5916 25.251 25.3019L42.8438 14.1907Z"
      fill="white"
    />
  </svg>
);

export default EmailIcon;
