import { teal } from '@engage/colors';

const CompletedIcon: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg viewBox="0 0 80 80" {...props}>
    <g id="page-/-mail-sent" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M15.243 25.56L27.971 38.493 62.313 1.037"
        transform="translate(-643 -200) translate(443 200) translate(200) translate(8.75 11.25)"
        stroke={teal}
        strokeWidth="2px"
        fillRule="nonzero"
      />
      <path
        d="M57 19.528c1.088 3.069 1.681 6.372 1.681 9.813 0 16.203-13.136 29.34-29.34 29.34C13.135 58.68 0 45.545 0 29.34 0 13.136 13.135 0 29.34 0c6.415 0 12.35 2.06 17.18 5.553"
        transform="translate(-643 -200) translate(443 200) translate(200) translate(8.75 11.25)"
        stroke={teal}
        strokeWidth="2px"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default CompletedIcon;
