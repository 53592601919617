import { useEffect } from 'react';
import { AuthEnv } from '@peloton/auth/authClient';
import { toAccountLink, toDigitalLink } from '@peloton/external-links';
import { white } from '@engage/colors';
import {
  toRemoteActivationPath,
  toRemoteIdQueryParam,
} from '@engage/remote-activation/urls';
import { useHref } from '../../hooks/useHref';
import { useAuth } from '../auth/OauthProvider';
import { getPersistedAuthData } from '../auth/persistAuthData';
import LoadingSpinner from '../loading/LoadingSpinner';

const PageRedirectView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { authEnv, remoteActivationId, sessionTLD } = useAuth();
  const { action } = getPersistedAuthData(authEnv);

  const href = useHref(
    authEnv,
    {
      account: toAccountLink(`${toRemoteActivationPath(remoteActivationId)}?redir=auth`),
      members: toDigitalLink(
        `/complete/welcome${
          authEnv === AuthEnv.Prod
            ? toRemoteIdQueryParam(remoteActivationId)
            : `${toRemoteIdQueryParam(remoteActivationId)}&api=qa1`
        }`,
      ),
    },
    sessionTLD,
  );

  useEffect(() => {
    if (!!window && !!href?.account && action === 'device_activation') {
      window.location.href = href.account;
    } else if (!!window && !!href?.members && action === 'add_account') {
      window.location.href = href.members;
    }
  }, [href]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <LoadingSpinner size={40} isLoading={true} fill={white} />
    </div>
  );
};

export default PageRedirectView;
