import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { hover } from '@peloton/styles';
import { slate1, gray1, gray2 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { interV } from '@engage/typography';

export const mainLinkStyles = css`
  align-items: center;
  display: inline-flex;
  fill: ${slate1};
  color: ${slate1};
  & + & {
    margin-left: 10px;
  }
`;

export const layoutTextStyles = css`
  font-family: ${interV};
  color: ${slate1};
`;

export const hoverLinkStyles = css`
  border-radius: ${spaces.xxxxSmall}px;
  cursor: pointer;
  ${hover`
    transition: background-color 250ms ease;
    background-color: ${gray2};
  `}
`;

const topNavStyles = css`
  ${layoutTextStyles}
  line-height: 1.3em;
  letter-spacing: 0.3px;
`;

export const navMainLink = css`
  ${topNavStyles}
  font-weight: 400;
  font-size: 14px;
  margin-bottom: ${spaces.xxxxSmall}px;
`;

export const navSubLink = css`
  ${topNavStyles}
  font-weight: 400;
  font-size: 13px;
`;

export const mainLinkCss = css`
  ${hoverLinkStyles}
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spaces.xxxSmall}px 15px ${spaces.xxSmall}px 15px;
`;

export const MainText = styled.div`
  ${navMainLink}
  margin-left: 5px;
`;

export const Separator = styled.div`
  height: 15px;
  width: 1px;
  background-color: ${transparentize(0.7, slate1)};
  margin: 0 5px;
`;

// MOBILE MENU
const mobileNavLinkStyles = css`
  ${layoutTextStyles}
  line-height: 1.25em;
  font-weight: 400;
`;

export const mobileNavMainLink = css`
  ${mobileNavLinkStyles}
  font-size: 20px;
  letter-spacing: -0.02em;
`;

export const mobileNavSubLink = css`
  ${mobileNavLinkStyles}
  font-size: 16px;
  letter-spacing: 0;
`;

// USER NAV
const userNavLinkStyles = css`
  ${layoutTextStyles}
  font-weight: 500;
  text-transform: uppercase;
`;

export const userNavLink = css`
  ${userNavLinkStyles}
  font-size: 13px;
  letter-spacing: 0.7px;
`;

export const mobileUserNavLink = css`
  ${userNavLinkStyles}
  font-size: 12px;
  letter-spacing: 0.6px;
`;

export const userNavUsername = css`
  ${layoutTextStyles}
  line-height: 1.3em;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.4px;
`;

export const userNavHoverLinkStyles = css`
  ${hoverLinkStyles}
  cursor: pointer;
  padding: 10px 15px;
  ${hover`
    background-color: ${gray1};
  `}
`;

// FOOTER
const footerLinkStyles = css`
  ${layoutTextStyles}
  font-weight: 300;
  line-height: 1.3em;
  letter-spacing: 0.2px;
`;

export const footerMainLink = css`
  ${footerLinkStyles}
  font-size: 15px;
`;

export const footerSubLink = css`
  ${footerLinkStyles}
  font-size: 13px;
`;
