import { UAParser } from 'ua-parser-js';
import type { ScreenPropsGetters } from '@peloton/analytics';
import { toDeviceCategoryFromWindow } from '@peloton/analytics';
import { isDefined } from '@peloton/types';
import { analyticsReady } from '@engage/analytics';
import { toSessionIdFromWindow } from './toSessionIdFromWindow';

/**
 * Amplitude rejects events where Session ID is undefined.
 */
type WebScreenPropsGetters = {
  browser(): string | undefined;
  browserVersion(): string | undefined;
  operatingSystem(): string | undefined;
  '[OS Version]'(): string | undefined;
  '[Session ID]'?(): string;
};

type PropsGetters = ScreenPropsGetters & WebScreenPropsGetters;

export const toWebBaseProperties = (parser = new UAParser()): PropsGetters => {
  const result = parser.getResult();

  const props = {
    platformType: () => 'Web',
    formFactor: toDeviceCategoryFromWindow,
    screenSize: toScreenSizeFromWindow,
    browser: () => result.browser.name,
    browserVersion: () => result.browser.version,
    operatingSystem: () => result.os.name,
    '[OS Version]': () => result.os.version,
  };

  /**
   * Wait for analytics to be ready and only add Session ID if it is valid.
   */
  analyticsReady().then(() => {
    const sessionId = toSessionIdFromWindow();
    if (isDefined(sessionId)) {
      props['[Session ID]'] = () => sessionId;
    }
  });

  return props;
};

const toScreenSizeFromWindow = (screen: Window = window) =>
  `${screen?.innerWidth} x ${screen?.innerHeight}`;

export const toBaseProperties = (parser = new UAParser()) => {
  const screenProps = toWebBaseProperties(parser);
  return isDefined(screenProps)
    ? Object.keys(screenProps).reduce(
        (acc, key) => ({ ...acc, [key]: screenProps[key]() }),
        {},
      )
    : {};
};
