import { load } from '@peloton/analytics';
import { makeAnalytics } from '@peloton/analytics/segment';
import { environmentFlags } from '@peloton/env';

export enum AnalyticsEvent {
  EnteredActivationCode = 'Entered Activation Code',
  MemberIsLoggedIn = 'Member Is Logged In',
  ViewedDeviceFlowVerification = 'Viewed Device Flow Verification',
  ActivatedDeviceFlow = 'Activated Device Flow',
}

enum SegmentKeyEnum {
  Dev = 'hYfSyPTz4G0age6HYnrLfvkedbSfYPeP',
  Uat = 'lPpEbPe0hAbW27ByP4zdvMbpEnCur6WS',
  Prod = 'zcOKJNJNisvk9S1wm2NtQ1yDUqh5cBbQ',
}

export const init: () => void = () => {
  const { isProd, isUat } = environmentFlags('auth')();
  const key = isProd
    ? SegmentKeyEnum.Prod
    : isUat
    ? SegmentKeyEnum.Uat
    : SegmentKeyEnum.Dev;

  load({ key }, makeAnalytics());
};
