import { useState, useEffect } from 'react';
import { AuthEnv } from '@peloton/auth/authClient';
import type { PeloLink, SocialLink } from '@peloton/external-links';
import { toHref, toExtLinkEnv } from '@peloton/external-links';
import type { SupportedTLD } from '@peloton/internationalize';
import { toExtLinkEnvOpts, OauthEnvironment } from '@engage/env/oauthEnv';
import { useLocale } from '../copy';
import { updateTLD } from '../utils/updateTLD';

type Links = Record<string, PeloLink | SocialLink>;

export const useHref = <T extends Links>(
  authEnv: AuthEnv,
  links: T,
  sessionTLD?: SupportedTLD,
): Record<keyof T, string> => {
  const locale = useLocale();
  const [hrefs, updateHrefs] = useState({});

  useEffect(() => {
    const newHrefs = Object.entries(links).reduce((href, [linkName, link]) => {
      return {
        ...href,
        [linkName]: toHref(
          link,
          updateTLD(
            toExtLinkEnv(
              toExtLinkEnvOpts(
                authEnv === AuthEnv.Prod ? OauthEnvironment.Prod : OauthEnvironment.Stage,
              ),
            ),
            locale,
            sessionTLD,
          ),
        ),
      };
    }, {});

    updateHrefs(newHrefs);
  }, []);

  return hrefs as Record<keyof T, string>;
};
