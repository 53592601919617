import styled from 'styled-components';
import { media } from '@peloton/styles';
import { spaces } from '@engage/styles';
import { Copy } from '../../copy/renderers';
import { mobileContentOffset, subtitleText, titleText } from '../ui/presets';
import CompletedIcon from './CompletedIcon';

const ActivationCompleteView: React.FC<
  React.PropsWithChildren<{ onContinue: () => void }>
> = ({ onContinue }) => {
  return (
    <Container>
      <CheckIconContainer>
        <CompletedIcon />
      </CheckIconContainer>
      <TitleText>
        <Copy id={'remoteActivation.successTitle'} />
      </TitleText>
      <SubtitleText>
        <Copy id={'remoteActivation.successOnDeviceSubtitle'} />
      </SubtitleText>
    </Container>
  );
};

const CheckIconContainer = styled.div`
  ${mobileContentOffset};
  width: 80px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${spaces.medium}px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleText = styled.div`
  ${titleText};
  margin-bottom: ${spaces.small}px;
`;

const SubtitleText = styled.div`
  ${media.tablet`
    padding: 0;
    width: 480px;
    margin-bottom: ${spaces.huge}px;
  `}
  padding: 0 12px;
  ${subtitleText};
  margin-bottom: ${spaces.xxxLarge}px;
`;

export default ActivationCompleteView;
