import { datadogRum } from '@datadog/browser-rum';
import type { ErrorInfo } from 'react';

export const logErrorBoundaryRender = (error: Error, errorInfo: ErrorInfo) => {
  // https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#react-error-boundaries-instrumentation
  const renderingError = new Error(error.message);
  renderingError.name = `ErrorBoundaryRendered`;
  renderingError.stack = errorInfo?.componentStack ?? '';
  (renderingError as any).cause = error;
  datadogRum.addError(renderingError);
  console.log('error', error);
};
