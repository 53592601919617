import styled from 'styled-components';
import { spaces } from '@engage/styles';
import { Copy } from '../../copy/renderers';
import { Button1Large } from '../buttons';
import { mobileContentOffset, subtitleText, titleText } from '../ui/presets';
import { useView } from '../ViewProvider';

const ActivationCancelledView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { setView } = useView();

  return (
    <Container>
      <TitleText data-test-id="titleText">
        <Copy id={'remoteActivation.cancelledTitle'} />
      </TitleText>
      <SubtitleText data-test-id="subText">
        <Copy id={'remoteActivation.cancelledSubtitle'} />
      </SubtitleText>
      <ContinueButton data-test-id="continueButton" onClick={() => setView('CodeInput')}>
        <Copy id={'remoteActivation.cancelledButtonCopy'} />
      </ContinueButton>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  text-align: center;
`;

const SubtitleText = styled.div<{ children?: React.ReactNode }>`
  ${subtitleText};
  margin-bottom: ${spaces.xxxLarge}px;
`;

const TitleText = styled.div<{ children?: React.ReactNode }>`
  ${mobileContentOffset};
  ${titleText};
  margin-bottom: ${spaces.small}px;
`;

const ContinueButton = styled(Button1Large)`
  width: 280px;
  display: block;
  margin: ${spaces.huge}px auto 0 auto;
`;

export default ActivationCancelledView;
