import type { FC } from 'react';
import { createContext, useContext } from 'react';
import { white } from '@engage/colors';
import type { PendingResult } from '@engage/result';
import { isPending, getOkValue } from '@engage/result';
import { DarkLayout, LoadingSpinner } from '../components';
import Segment from '../components/Segment';
import type { CopyFile } from './models';
import { SupportedLocale } from './models';
import germanGermanyCopy from './static/de-DE.generated';
import englishUnitedStatesCopy from './static/en-US.generated';
import spanishMexicoCopy from './static/es-MX.generated';

type Copy = CopyFile;

const COPY: Record<SupportedLocale, CopyFile> = {
  [SupportedLocale.EnglishUnitedStates]: englishUnitedStatesCopy,
  [SupportedLocale.EnglishCanada]: englishUnitedStatesCopy,
  [SupportedLocale.EnglishAustralia]: englishUnitedStatesCopy,
  [SupportedLocale.EnglishUnitedKingdom]: englishUnitedStatesCopy,
  [SupportedLocale.GermanGermany]: germanGermanyCopy,
  [SupportedLocale.SpanishMexico]: spanishMexicoCopy,
};

const CONTEXT = createContext<{ copy: Copy; locale: SupportedLocale }>({
  copy: englishUnitedStatesCopy,
  locale: SupportedLocale.EnglishUnitedStates,
});

type Props = {
  pendingLocale: PendingResult<SupportedLocale, string>;
};

export const CopyProvider: FC<React.PropsWithChildren<Props>> = ({
  children,
  pendingLocale,
}) => {
  if (isPending(pendingLocale)) {
    return (
      <DarkLayout>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Segment />
          <LoadingSpinner size={40} isLoading={true} fill={white} />
        </div>
      </DarkLayout>
    );
  }

  const locale = getOkValue(pendingLocale) ?? SupportedLocale.Default;
  return (
    <CONTEXT.Provider value={{ copy: COPY[locale] ?? englishUnitedStatesCopy, locale }}>
      {children}
    </CONTEXT.Provider>
  );
};

export const useAllCopy = () => useContext(CONTEXT).copy;
export const useLocale = () => useContext(CONTEXT).locale;

export default CONTEXT;
