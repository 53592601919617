import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useState, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { pending, ok } from '@engage/result';
import type { PendingResult } from '@engage/result';
import { init as initAnalytics } from '../analytics';
import { DarkLayout } from '../components';
import HumanSecurityAPI from '../components/HumanSecurityAPI';
import { Oops } from '../components/Oops';
import Segment from '../components/Segment';
import { useCopy } from '../copy';
import { CopyProvider } from '../copy/context';
import { SupportedLocale } from '../copy/models';
import { init as initDataDog } from '../datadog';
import { toAssetPath, toEnvVariables } from '../env';
import '../components/globalStyles.css';
import { logErrorBoundaryRender } from '../utils/logErrorBoundaryRender';

const WrappedApp: React.FC<React.PropsWithChildren<AppProps>> = ({
  Component,
  pageProps,
}) => {
  const login = useCopy('auth.logIn');
  useEffect(() => {
    initDataDog(toEnvVariables());
    initAnalytics();
  }, []);

  return (
    <>
      <Head>
        <title>{login} | Peloton</title>
        <meta name="robots" content="noindex" />
        <link rel="shortcut icon" href={toAssetPath('/favicon.png')} />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;900&display=swap"
          rel="stylesheet"
        />
        <link rel="preload" href={toAssetPath('/dotsLeft.svg')} as="image" />
        <link rel="preload" href={toAssetPath('/dotsRight.svg')} as="image" />
        <link href={toAssetPath('/fontAndBackground.css')} rel="stylesheet" />
        <HumanSecurityAPI />
        <Segment />
      </Head>
      <Component {...pageProps} />
    </>
  );
};

const MyApp: React.FC<React.PropsWithChildren<AppProps>> = props => {
  const [localeOrDefault, setLocaleOrDefault] = useState<
    PendingResult<SupportedLocale, string>
  >(pending);

  useEffect(() => {
    if (!!navigator) {
      const browserLocales =
        navigator?.languages?.length > 0 ? navigator.languages : [navigator.language];
      const browserLanguages = browserLocales.map(lang => lang.slice(0, 2));

      const supportedLocale = browserLocales.find(lang => lang in SupportedLocale) as
        | SupportedLocale
        | undefined;

      const supportedLocaleFromLanguage = browserLanguages.reduceRight((acc, lang) => {
        return (
          (Object.values(SupportedLocale).find(
            (locale: string) => locale.slice(0, 2) === lang,
          ) as SupportedLocale) ?? acc
        );
      }, SupportedLocale.Default);

      // fallback to language instead of locale if no locales match
      setLocaleOrDefault(
        ok<SupportedLocale>(
          (supportedLocale ?? supportedLocaleFromLanguage) as SupportedLocale,
        ),
      );
    } else {
      setLocaleOrDefault(ok(SupportedLocale.Default));
    }
  }, []);
  return (
    <CopyProvider pendingLocale={localeOrDefault}>
      <ErrorBoundary
        fallback={
          <DarkLayout>
            <Oops />
          </DarkLayout>
        }
        onError={logErrorBoundaryRender}
      >
        <WrappedApp {...props} />
      </ErrorBoundary>
    </CopyProvider>
  );
};

export default MyApp;
