import type { FC } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { Links } from '@peloton/links';
import { media } from '@peloton/styles';
import { gray4, slate1, white } from '@engage/colors';
import { spaces } from '@engage/styles';
import { Copy, useLocale } from '../../copy';
import { SupportedLocale } from '../../copy/models';
import { useHref } from '../../hooks';
import { useAuth } from '../auth/OauthProvider';
import { footerMainLink, footerSubLink } from '../sharedLinkStyles';
import SocialLinks from './SocialLinks';

const getCopyrightID = (locale: SupportedLocale) => {
  switch (locale) {
    case SupportedLocale.GermanGermany:
      return 'site.copyrightDE';
    case SupportedLocale.EnglishUnitedKingdom:
      return 'site.copyrightGB';
    case SupportedLocale.EnglishUnitedStates:
    default:
      return 'site.copyright';
  }
};

const FooterLink: FC<
  React.PropsWithChildren<{ href: string; darkTheme: boolean; fontSize?: number }>
> = ({ darkTheme, href, children, fontSize }) => {
  const [hover, setHover] = useState(false);

  return (
    <button
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => setHover(false)}
    >
      <Link darkTheme={darkTheme} fontSize={fontSize} href={href}>
        <div className={`${hover ? 'hovered' : ' '}`}>{children}</div>
      </Link>
      <style jsx>{`
        .hovered {
          transition: 0.25s;
          color: ${white};
        }
      `}</style>
    </button>
  );
};

const FooterView: React.FC<React.PropsWithChildren<{ darkTheme: boolean }>> = ({
  darkTheme = true,
  ...props
}) => {
  const links = {
    pelotonHref: Links.peloton,
    boutiqueHref: Links.boutique,
    studioHref: Links.oldStudioHome,
    blogHref: Links.blog,
    privacyHref: Links.privacy,
    tosHref: Links.tos,
    supportHref: Links.support,
    impressumHref: Links.impressum,
    membershipTermsHref: Links.membershipTerms,
    ipPolicyHref: Links.ipPolicy,
    accessibilityHref: Links.accessibility,
  };

  const { authEnv } = useAuth();
  const locale = useLocale();
  const copyrightId = getCopyrightID(locale);

  const hrefs = useHref(authEnv, links);

  return (
    <Container>
      <SocialLinks darkTheme={darkTheme} />
      <MainLinks>
        <FooterLink darkTheme={darkTheme} href={hrefs.pelotonHref}>
          Peloton
        </FooterLink>
        <FooterLink darkTheme={darkTheme} href={hrefs.boutiqueHref}>
          <Copy id="links.boutique" />
        </FooterLink>
        <FooterLink darkTheme={darkTheme} href={hrefs.studioHref}>
          <Copy id="links.studio" />
        </FooterLink>
        <FooterLink darkTheme={darkTheme} href={hrefs.blogHref}>
          <Copy id="links.blog" />
        </FooterLink>
        <FooterLink darkTheme={darkTheme} href={hrefs.supportHref}>
          <Copy id="links.support" />
        </FooterLink>
      </MainLinks>
      <SubLinks>
        <FooterLink darkTheme={darkTheme} href={hrefs.tosHref} fontSize={12}>
          <Copy id="links.termsOfService" />
        </FooterLink>
        <FooterLink darkTheme={darkTheme} href={hrefs.membershipTermsHref} fontSize={12}>
          <Copy id="links.membershipTerms" />
        </FooterLink>
      </SubLinks>
      <SubLinks>
        <FooterLink darkTheme={darkTheme} href={hrefs.privacyHref} fontSize={12}>
          <Copy id="links.privacy" />
        </FooterLink>
        {locale === SupportedLocale.EnglishUnitedStates && (
          <FooterLink darkTheme={darkTheme} href={hrefs.ipPolicyHref} fontSize={12}>
            <Copy id="links.ipPolicy" />
          </FooterLink>
        )}
        {locale === SupportedLocale.GermanGermany && (
          <FooterLink darkTheme={darkTheme} href={hrefs.impressumHref} fontSize={12}>
            <Copy id="links.impressum" />
          </FooterLink>
        )}
        <FooterLink darkTheme={darkTheme} href={hrefs.accessibilityHref} fontSize={12}>
          <Copy id="links.accessibility" />
        </FooterLink>
      </SubLinks>
      <Copyright darkTheme={darkTheme}>
        <div>
          <Copy
            id={copyrightId}
            args={{ startYear: '2018', endYear: new Date().getFullYear() }}
          />
        </div>
      </Copyright>
    </Container>
  );
};

const Container = styled.footer`
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: ${spaces.huge}px;
  padding-bottom: 30px;
  width: 100%;
  ${media.desktop`
    margin-top: 60px;
  `}
`;

const MainLinks = styled.ul`
  ${footerMainLink}
  padding-top: ${spaces.small}px;
`;

const SubLinks = styled.ul`
  ${footerSubLink}
  margin-top: 10px;
`;

const Link: React.FC<React.PropsWithChildren<LinkProps>> = props => (
  <LinkWrapper>
    <StyledLink
      href={props.href}
      target="_blank"
      rel="noopener"
      darkTheme={props.darkTheme}
      fontSize={props.fontSize}
    >
      {props.children}
    </StyledLink>
  </LinkWrapper>
);

const StyledLink = styled.a<{ darkTheme: boolean; fontSize?: number }>`
  font-size: ${props => props.fontSize ?? '13'}px;
  color: ${props => (props.darkTheme ? gray4 : slate1)};
`;

const LinkWrapper = styled.li`
  display: inline-block;
  margin-right: ${spaces.large}px;
`;

const Copyright = styled.div<{ darkTheme: boolean }>`
  ${footerSubLink}
  font-size: 12px;
  color: ${props => (props.darkTheme ? gray4 : slate1)};
  padding-top: 13px;
`;

type LinkProps = {
  href: string;
  darkTheme: boolean;
  fontSize?: number;
};

export default FooterView;
