import Cookies from 'cookies-js';
import { useRouter } from 'next/router';
import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { spaces } from '@engage/styles';
import { Copy } from '../../copy/renderers';
import { toEnvVariables } from '../../env/env';
import { Button1Large } from '../buttons/';
import { titleText } from '../ui/presets';
import EmailIcon from './EmailIcon';
import ExpiredIcon from './ExpiredIcon';
import VerifiedIcon from './VerifiedIcon';

const ValidationText = styled.h1`
  color: white;
  text-align: center;
`;

const resultCopy = {
  success: <Copy id={'emailValidationPage.ctaRegistrationSuccessful'} />,
  failure: <Copy id={'emailValidationPage.ctaRegistrationUnsuccessful'} />,
};
const ctaText = (result: string) =>
  resultCopy[result as string] ? resultCopy[result as string] : '';

const ctaLink = (
  result: string,
  memberProfileLinkDomain: string,
  registrationPageLink: string,
) => (result === 'success' ? memberProfileLinkDomain : registrationPageLink);

const titleStates = {
  success: <Copy id={'emailValidationPage.titleSuccessfulRegistration'} />,
  failure: <Copy id={'emailValidationPage.registrationErrorDetails'} />,
};

const validationStates = {
  failure: <></>,
  invalid: (
    <ValidationText>
      <Copy id={'emailValidationPage.invalid'} />
    </ValidationText>
  ),
  success: (
    <ValidationText>
      <Copy id={'emailValidationPage.registrationSuccess'} />
    </ValidationText>
  ),
};

const validationIcons = {
  failure: <ExpiredIcon />,
  success: <VerifiedIcon />,
  confirm: <EmailIcon />,
  pending: <EmailIcon />,
};

const EmailVerificationOnRegistrationView: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { query } = useRouter();
  const isProd = toEnvVariables().ENVIRONMENT === 'PRODUCTION';
  const result = query.result;
  const registrationLinkDomain = isProd
    ? 'www.onepeloton.com'
    : 'www.test.onepeloton.com';
  const memberProfileLinkDomain = isProd
    ? 'https://members.onepeloton.com'
    : 'https://members.test.onepeloton.com';
  const registrationPageLink = `https://${registrationLinkDomain}/register`;
  const redirectLink = useRef('');
  useEffect(() => {
    const cookieRedirectLink = Cookies.get('peloton_register_redirect');
    console.log(cookieRedirectLink);
    // cookie adds a comma before the html, so we're removing it here
    if (cookieRedirectLink) {
      redirectLink.current = cookieRedirectLink.slice(1);
    }
  }, [memberProfileLinkDomain]);

  const ctaLinkUrl = ctaLink(
    result as string,
    redirectLink.current ? redirectLink.current : memberProfileLinkDomain,
    registrationPageLink,
  );

  return (
    <EmailVerificationContainer>
      <ValidationIconContainer>
        {validationIcons[result as string]}
      </ValidationIconContainer>
      <TitleText>
        <h1>{titleStates[result as string] || ''}</h1>
      </TitleText>
      {validationStates[result as string]}
      <br />
      {(result === 'success' || result === 'failure') && (
        <a href={ctaLinkUrl}>
          <CTAButton>{ctaText(result)}</CTAButton>
        </a>
      )}
      {(!result || (result !== 'success' && result !== 'failure')) &&
        validationStates['invalid']}
    </EmailVerificationContainer>
  );
};

export default EmailVerificationOnRegistrationView;

const ValidationIconContainer = styled.div`
  margin-bottom: ${spaces.large}px;
`;

const CTAButton = styled(Button1Large)`
  margin-top: 32px;
`;

const EmailVerificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
`;

const TitleText = styled.div`
  ${titleText};
  margin-bottom: ${spaces.small}px;
`;
