import { datadogRum } from '@datadog/browser-rum';
import { useRouter } from 'next/router';
import React from 'react';
import styled from 'styled-components';
import { Copy } from '../../copy/renderers';
import { identityDomain, domain as authDomain } from '../auth/OauthProvider';
import { useError } from '../Oops/ErrorContext';
import { useEmailVerificationPolling } from './useEmailVerificationPolling';

type ToastFunction = (message: React.ReactNode) => void;
const resendEmail = (email: string, openToast: ToastFunction) => async () => {
  const defaultErrorMessage = <Copy id={'emailValidationPage.invalid'} />;
  const successMessage = <Copy id={'emailValidationPage.emailResent'} />;

  try {
    const response = await fetch(
      `https://${identityDomain}/auth/email/resend_verification?email=${encodeURIComponent(
        email,
      )}`,
    );
    let errorMessage;
    if (!response.ok) {
      errorMessage = await response.json();
      throw new Error(errorMessage);
    }
    openToast(successMessage);
    console.log(successMessage);
  } catch (e) {
    openToast(defaultErrorMessage);
    datadogRum.addError(e);
    console.log(e);
  }
};

const FirstTimeCopy = ({
  email,
  openToast,
}: {
  email: string;
  openToast: ToastFunction;
}) => (
  <>
    <Title>
      <Copy id={'emailValidationPage.registrationConfirmationRequiredTitle'} />
    </Title>
    <Text>
      <Copy
        id={'emailValidationPage.registrationConfirmationRequiredBody1'}
        args={{ userEmail: email }}
      />
    </Text>
    <Text>
      <Copy id={'emailValidationPage.registrationConfirmationRequiredBody2'} />
    </Text>
    <ResendLink onClick={resendEmail(email, openToast)}>
      <Copy id={'emailValidationPage.ctaRegistrationConfirmationRequired'} />
    </ResendLink>
  </>
);

const RepeatCopy = ({
  email,
  openToast,
}: {
  email: string;
  openToast: ToastFunction;
}) => (
  <>
    <Title>
      <Copy id={'emailValidationPage.registrationPendingTitle'} />
    </Title>

    <ResendLink onClick={resendEmail(email, openToast)}>
      <Copy id={'emailValidationPage.ctaRegistrationConfirmationRequired'} />
    </ResendLink>
  </>
);

const InvalidError = () => {
  return (
    <Section>
      <Text>
        <Copy id={'emailValidationPage.invalid'} />
      </Text>
    </Section>
  );
};

const ErrorCopy = {
  '3195': FirstTimeCopy,
  '3196': RepeatCopy,
};

const getErrorCode = (error: string) => {
  const pattern = /\((\d+)\)/;
  const match = error.match(pattern);
  return match ? match[1] : '';
};

const UnverifiedView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { query } = useRouter();
  const state = (query?.state as string) || '';
  const redirectUri = (query?.redirect_uri as string) || '';
  const email = decodeURIComponent(query?.email as string) || '';
  const error = getErrorCode(decodeURIComponent(query?.error as string) || '');

  const ErrorComponent = ErrorCopy[error];
  const { setErrorMessage } = useError();

  // this hook will poll the email verification status
  // it will redirect the user to the auth0 continue endpoint and to the redirectUri once verified
  useEmailVerificationPolling(
    email,
    `https://${authDomain}/continue?state=${state}&redirect_uri=${redirectUri}`,
  );

  return (
    <Section>
      {ErrorCopy[error] ? (
        <ErrorComponent email={email} openToast={setErrorMessage} />
      ) : (
        <InvalidError />
      )}
    </Section>
  );
};

const Text = styled.h4`
  color: white;
  text-align: center;
  line-height: 24px;
  padding: 9px;
  font-weight: 300;
  letter-spacing: 0.025em;
  line-height: 1.5em;
  font-size: 16px;
`;

const Section = styled.div`
  position: relative;
  border-radius: 8px;
  margin-top: -32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
`;

const ResendLink = styled.button`
  font-weight: 400;
  color: white;
  text-align: center;
  margin: 16px;
  position: relative;

  &:hover {
    text-decoration: underline;
    transition: all 1s ease;
    cursor: pointer;
  }
`;

const Title = styled.h1`
  color: white;
  font-size: 28px;
  text-align: center;
  padding: 32px 12px 8px;
  line-height: 40px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.375em;
`;

export default UnverifiedView;
