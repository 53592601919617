import styled from 'styled-components';
import { white, backgroundInputGray, superRedA11y, gray4 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { LoadingSpinner } from '../loading';
import { Button1Large } from './buttons';

const ActionButton = styled(Button1Large)<ActionButtonProps>`
  display: block;
  margin: 0 auto;

  ${props =>
    !props.isValid
      ? `:disabled {
          color: ${gray4};
          background-color: ${backgroundInputGray};
          cursor: not-allowed;
          &:hover {
            filter: none;
          }
      }`
      : ''}
  ${props =>
    props.isSubmitting
      ? `:disabled {
          background-color: ${superRedA11y};
          color: ${white};
      }`
      : ''}
`;

const SubmitText = ({ text }: { text: string }) => <span>{text}</span>;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin-top: ${spaces.xxxxSmall}px;
`;

const SubmitButton: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({
  isSubmitting,
  isValid = true,
  text,
  StyledButton = ActionButton,
  fill = white,
  loaderSize = 28,
  ...props
}) => (
  <StyledButton
    type="submit"
    disabled={isSubmitting || !isValid}
    isValid={isValid}
    isSubmitting={isSubmitting}
    {...props}
  >
    {isSubmitting ? (
      <StyledLoadingSpinner isLoading size={loaderSize} fill={fill} />
    ) : (
      <SubmitText text={text} />
    )}
  </StyledButton>
);

export type ActionButtonProps = Partial<
  Pick<SubmitButtonProps, 'isSubmitting' | 'isValid'>
>;

type SubmitButtonProps = {
  onClick?: (e: React.SyntheticEvent) => void;
  isSubmitting: boolean;
  text: string;
  isValid?: boolean;
  fill?: string;
  loaderSize?: number;
  StyledButton?: React.ComponentType<
    React.PropsWithChildren<
      ActionButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
    >
  >;
};

export default SubmitButton;
