import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { defaultTransition, hover } from '@peloton/styles';

import { gray3, white, superRedA11y, gray5, slate1 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { interV } from '@engage/typography';

export const buttonText = css`
  text-align: center;
  font-family: ${interV};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

export const sharedButtonStyles = css`
  ${buttonText}
  border-radius: 50px;
  cursor: pointer;
  border: none;
`;

const disabledButtonStyles = css`
  &:disabled {
    cursor: default;
    background-color: ${gray3};
    color: ${gray5};
    box-shadow: none;
    border: none;
  }
`;

export const largeButtonStyles = css`
  height: ${spaces.xHuge}px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1px;
  border-radius: ${spaces.xLarge}px;
  min-width: 200px;
  padding: 0 30px ${spaces.tiny}px;
`;

// Button 1

export const button1Styles = css`
  ${sharedButtonStyles}
  color: ${white};
  background-color: ${superRedA11y};
  ${defaultTransition('filter, background-color, color')}
  ${hover`filter: brightness(85%);`}
    &:active {
    filter: brightness(85%);
  }
  ${disabledButtonStyles}
`;

const Button1 = styled.button`
  ${button1Styles}
`;

export const Button1Large = styled(Button1)`
  ${largeButtonStyles}
`;

export const darkDisabled = css`
  :disabled {
    background-color: ${transparentize(0.9, white)};
    color: ${gray5};
  }
  cursor: auto;
`;

export const button2Styles = css`
  ${sharedButtonStyles};
  background-color: ${slate1};
  color: ${white};
  ${defaultTransition('filter, background-color, color')}
  ${hover`filter: brightness(85%);`}
  &:active {
    filter: brightness(85%);
  }
  ${disabledButtonStyles};
`;

export const darkStyle2 = css`
  background-color: ${transparentize(0.85, white)};
  color: ${white};
  ${defaultTransition('filter')}
  &:active {
    filter: brightness(120%);
  }
  ${darkDisabled}
`;
