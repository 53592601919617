import { useEffect, useState } from 'react';
import type { OauthFragment } from '@engage/auth/oauthFragment';

export const useErrorMessage = (oauthFragment: OauthFragment | undefined) => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (!!oauthFragment) {
      setMessage(decodeURIComponent(oauthFragment?.message ?? ''));
    }
  }, [oauthFragment]);

  const clearMessage = () => setMessage('');

  return [message, clearMessage] as const;
};
