import axios from 'axios';
import {
  toDefaultRequestTransforms,
  toDefaultResponseTransforms,
} from '@peloton/api/client-transforms';
import { AuthEnv } from '@peloton/auth/authClient';
import { toApiLink, toExtLinkEnv, toHref, ApiEnvs } from '@peloton/external-links';

import { BaseAPI } from '@engage/api-v2';

const createApiClient = (authEnv: AuthEnv) => {
  const api = authEnv === AuthEnv.Prod ? ApiEnvs.Prod : ApiEnvs.Qa1;
  const extLink = toExtLinkEnv({ api });

  return axios.create({
    baseURL: toHref(toApiLink('/'), extLink),
    responseType: 'json',
    transformRequest: toDefaultRequestTransforms(),
    transformResponse: toDefaultResponseTransforms(),
    withCredentials: true,
  });
};

export const engageApi = (authEnv: AuthEnv) => new BaseAPI(createApiClient(authEnv));
