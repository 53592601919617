import type { FC } from 'react';
import React from 'react';
import toFormattedText from '@peloton/copy/toFormattedText';
import { useAllCopy, useLocale } from './context';
import enUSCopy from './static/en-US.generated';
import type { CopyKey } from './static/types';

const REGEX = /(?<collection>\w+)\.(?<item>.+)/;

export const useCopy = (id: CopyKey, args: Record<string, any> = {}) => {
  const copy = useAllCopy();
  const locale = useLocale();

  if (!REGEX.test(id)) {
    console.error(`invalid id ${id}`);
    return '';
  }

  const { collection, item } = id.match(REGEX)?.groups ?? {};
  const value = copy[collection]?.[item] ?? enUSCopy[collection]?.[item] ?? '';

  if (!value) {
    console.error(`No copy found for id ${id}`);
    return '';
  }

  if (copy[collection]?.[item] === undefined) {
    console.warn(`No copy found for id ${id} in ${locale} - falling back to en-US`);
  }

  return toFormattedText(value, args);
};

type Props = {
  id: CopyKey;
  args?: Record<string, any>;
};

export const Copy: FC<React.PropsWithChildren<Props>> = ({ id, args }) => (
  <>{useCopy(id, args)}</>
);
