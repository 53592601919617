import { BreakpointWidth } from '@peloton/styles';
import { slate3 } from '@engage/colors';
import { toAssetPath } from '../../env';

const dotsLeft = toAssetPath('/dotsLeft.svg');
const dotsRight = toAssetPath('/dotsRight.svg');

const DotsBackground: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <section className="dots-background">
      {children}
      <style jsx>{`
        .dots-background {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          width: 100%;
          min-height: 100vh;
          height: auto;
          background-repeat: no-repeat;
          background-position: top left, bottom right;
          background-size: auto 300px;
          background-image: url(${dotsLeft}), url(${dotsRight});
          background-color: ${slate3};
        }

        @media (min-width: ${BreakpointWidth.tablet}px) {
          .dots-background {
            background-size: auto 400px;
          }
        }
      `}</style>
    </section>
  );
};

export default DotsBackground;
