import React from 'react';
import styled from 'styled-components';
import {
  isBackspaceKey,
  isLeftArrowKey,
  isRightArrowKey,
} from '@peloton/keyboard/keyCheck';
import { media } from '@peloton/styles';
import {
  backgroundHoverInputGray,
  backgroundInputGray,
  gray2,
  gray5,
} from '@engage/colors';
import { title32Bold, title28Bold } from '@engage/typography';

const CodeInput: React.FC<
  React.PropsWithChildren<{
    initialCode: string;
    length: number;
    handleCodeUpdate: (newCode: string) => any;
  }>
> = ({ initialCode, length, handleCodeUpdate }) => {
  const [values, setValues] = React.useState<string[]>(new Array(length).fill(''));
  const [selection, setSelection] = React.useState<number>(-1);

  const textInputs: React.MutableRefObject<(HTMLInputElement | null)[]> = React.useRef(
    [],
  );

  React.useEffect(() => {
    if (selection === null) return;
    textInputs.current[selection]?.setSelectionRange(
      0,
      textInputs.current[selection]?.value.length || 0,
    );
  }, [selection]);

  React.useEffect(() => {
    initialCode.length > 0 && setValues(initialCode.split(''));
  }, [initialCode]);

  React.useEffect(() => {
    handleCodeUpdate(values.join(''));
  }, [values]);

  const moveLeft = (index: number) => {
    if (index > 0) {
      textInputs.current[index - 1]?.focus();
    }
  };

  const moveRight = (index: number) => {
    if (index < length - 1) {
      textInputs.current[index + 1]?.focus();
    }
  };

  const handleFocus = (index: number) => {
    setSelection(index);
  };

  const handleBlur = () => setSelection(-1);

  const updateValues = (newValue: string, index: number) => {
    const newValues = [...values];
    newValues[index] = newValue.toUpperCase();
    setValues(newValues);
  };

  const handleChange = (e: any, index: number) => {
    const alphanumericRegex = /^[a-zA-Z0-9]$/;
    if (alphanumericRegex.test(e.target.value)) {
      updateValues(e.target.value, index);
      moveRight(index);
    } else {
      e.target.value = '';
      updateValues(e.target.value, index);
      e.preventDefault();
    }
  };

  const handleKeydown = (e: any, index: number) => {
    const emptyField = e.target.value == '';
    if (isBackspaceKey(e)) {
      updateValues('', index);
      if (emptyField) {
        moveLeft(index);
      }
    } else if (isLeftArrowKey(e)) {
      moveLeft(index);
      e.preventDefault();
    } else if (isRightArrowKey(e)) {
      moveRight(index);
    }
  };

  const handleClick = (index: number) => {
    if (!values[index]) {
      const focusedIndex = Math.floor(index / 3) * 3;
      textInputs.current[focusedIndex]?.focus();
    }
  };

  return (
    <CodeInputContainer data-test-id="codeInputForm">
      {[...Array(length)].map((_, i) => {
        return (
          <React.Fragment key={i}>
            {showHyphen(i)}
            <CodeSingleInputContainer>
              {!values[i] && selection !== i && <Placeholder index={i} />}
              <CodeSingleInput
                data-test-id="codeSingleInput"
                defaultValue={values[i]}
                type="tel"
                aria-label={`code input ${i + 1}`}
                onFocus={() => handleFocus(i)}
                onBlur={handleBlur}
                onClick={() => handleClick(i)}
                onChange={e => handleChange(e, i)}
                onKeyDown={e => handleKeydown(e, i)}
                maxLength={1}
                ref={ref => {
                  textInputs.current[i] = ref;
                }}
                index={i}
                selected={selection}
              />
            </CodeSingleInputContainer>
          </React.Fragment>
        );
      })}
    </CodeInputContainer>
  );
};

const showHyphen = (i: number) => {
  if (i === 3 || i === 6) {
    return <Hyphen>-</Hyphen>;
  }
  return <></>;
};

const Hyphen = styled.div`
  display: inline-block;
  color: ${gray2};
  margin-left: 4px;
  margin-right: 4px;
  font-size: 20px;

  ${media.tablet`
    font-size: 32px;
    margin-left: 8px;
    margin-right: 8px;
  `}
`;

const Placeholder = styled.div<{ index: number }>`
  position: absolute;
  width: 50%;
  border-bottom: solid 1px ${gray5};

  bottom: 8px;

  ${media.tablet`
    width: 22px;
    bottom: 16px;
  `}

  left: 0;
  right: 0;
  margin: 0 auto;
`;

const CodeInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
`;

const CodeSingleInputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const CodeSingleInput = styled.input<{ index: number; selected: number }>`
  ${title28Bold};
  @media (min-width: 580px) {
    ${title32Bold};
    height: 64px;
    width: 100%;
  }
  height: 48px;
  width: 100%;
  font-size: 20px;

  background-color: ${p =>
    Math.floor(p.selected / 3) === Math.floor(p.index / 3)
      ? `${backgroundHoverInputGray}`
      : `${backgroundInputGray}`};

  :focus {
    outline: none;
  }

  border-top-left-radius: ${p => (p.index % 3 === 0 ? 5 : 0)}px;
  border-bottom-left-radius: ${p => (p.index % 3 === 0 ? 5 : 0)}px;
  border-top-right-radius: ${p => (p.index % 3 === 2 ? 5 : 0)}px;
  border-bottom-right-radius: ${p => (p.index % 3 === 2 ? 5 : 0)}px;
  color: ${gray2};
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  margin: 0;

  ${media.tablet`
    height: 58px;
    width: 43px;
    ${title28Bold};
  `}
`;

export default CodeInput;
