import React from 'react';

const VerifiedIcon: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.15625 24C6.15625 14.3624 14.8624 5.65625 24.5 5.65625C28.3664 5.65625 31.2403 6.6156 34.136 8.54603C34.4375 8.74708 34.845 8.66559 35.046 8.36402C35.2471 8.06246 35.1656 7.65501 34.864 7.45397C31.7597 5.3844 28.6336 4.34375 24.5 4.34375C14.1376 4.34375 4.84375 13.6376 4.84375 24C4.84375 34.3624 14.1376 43.6562 24.5 43.6562C34.8624 43.6562 44.1562 34.3624 44.1562 24V23.9485C44.1563 21.9795 44.1564 19.85 43.124 17.7144C42.9663 17.3881 42.5739 17.2514 42.2476 17.4092C41.9213 17.5669 41.7846 17.9593 41.9423 18.2856C42.8346 20.1313 42.8437 21.9834 42.8437 24C42.8437 33.6376 34.1376 42.3437 24.5 42.3437C14.8624 42.3437 6.15625 33.6376 6.15625 24ZM43.9856 8.44145C44.2294 8.17326 44.2096 7.75822 43.9414 7.51442C43.6733 7.27062 43.2582 7.29038 43.0144 7.55856L23.451 29.0784L14.936 21.5095C14.6651 21.2687 14.2503 21.2931 14.0095 21.564C13.7687 21.8349 13.7931 22.2497 14.064 22.4905L23.064 30.4905C23.3328 30.7294 23.7437 30.7075 23.9856 30.4414L43.9856 8.44145Z"
      fill="#50C4AA"
    />
  </svg>
);

export default VerifiedIcon;
