import type { FormikActions, FormikProps } from 'formik';
import { Formik, Field } from 'formik';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ready } from '@peloton/analytics';
import { parseSearch } from '@peloton/redux';
import { spaces } from '@engage/styles';
import { AnalyticsEvent } from '../../analytics';
import { useCopy, Copy } from '../../copy/renderers';
import { useTrackingCallback } from '../../hooks';
import { useAuth } from '../auth/OauthProvider';
import SubmitButton from '../buttons/SubmitButton';
import { useError } from '../Oops/ErrorContext';
import { subtitleText, titleText, mobileContentOffset } from '../ui/presets';
import { useView } from '../ViewProvider';
import CodeInput from './CodeInput';

const CODE_LENGTH = 9;

enum FormField {
  UserCode = 'userCode',
  DefaultValue = 'defaultValue',
}

type FormValues = Record<FormField, string>;

const CodeInputView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { verifyDevice, logoutLegacySession } = useAuth();
  const { setErrorMessage } = useError();
  const { track } = useTrackingCallback();
  const invalidCodeError = useCopy('remoteActivation.enterCodeInvalid') as string;
  const noNetworkError = useCopy('site.networkError') as string;
  const [codeValue, setCodeValue] = useState<string>('');
  const router = useRouter();

  // Some applications will prefer legacy session auth, overriding the Oauth login that occurs as part of device activation, causing issues.
  useEffect(() => {
    logoutLegacySession();
  }, []);

  useEffect(() => {
    if (
      !!window &&
      !!window?.analytics &&
      !window?.location.search.includes('?code' || '&code')
    ) {
      // send analytics when user is viewing this page for the first time
      // not when they get returned from auth0
      ready(() => {
        // needed to wrap this track in ready otherwise the event gets passed in nested
        track(AnalyticsEvent.ViewedDeviceFlowVerification);
      });
    }
  }, [!!window?.analytics]);

  const verifyDeviceAndUpdateView = async (userCode: string) => {
    try {
      await verifyDevice(userCode);
    } catch (e) {
      setErrorMessage(invalidCodeError);
    }

    return null;
  };

  const handleSubmit = async (
    values: FormValues,
    formikActions: FormikActions<FormValues>,
  ) => {
    setErrorMessage('');
    if (!window.navigator.onLine) {
      setErrorMessage(noNetworkError);
    } else {
      await verifyDeviceAndUpdateView(
        `${values.userCode.slice(0, 3)}-${values.userCode.slice(
          3,
          6,
        )}-${values.userCode.slice(6)}`,
      );
    }

    formikActions.setSubmitting(false);
  };

  const initialStatus = null;

  useEffect(() => {
    if (!!window) {
      const params = parseSearch(window.location);
      const userCode = params['user_code'] ?? '';

      if (!!userCode) {
        router.replace(window.location.pathname, undefined, { shallow: true });
        setCodeValue(userCode.replaceAll('-', ''));
      }
    }
  }, []);

  return (
    <Formik
      enableReinitialize
      isInitialValid={false}
      initialValues={{
        userCode: '',
        defaultValue: codeValue,
      }}
      onSubmit={handleSubmit}
      children={props => (
        <Form
          {...(((initialStatus && { status: initialStatus }) as unknown) as Record<
            string,
            any
          >)}
          {...props}
        />
      )}
    />
  );
};

const Form: React.FC<React.PropsWithChildren<FormikProps<FormValues>>> = ({
  handleSubmit,
  isSubmitting,
  values,
  initialValues,
}) => {
  const [isReentry, setIsReentry] = useState(false);

  useEffect(() => {
    if (!!window) {
      const params = parseSearch(window.location);
      setIsReentry(!!params['reenter']);
    }
  }, []);

  const { screenHint } = useView();
  return (
    <StyledForm onSubmit={handleSubmit}>
      <TitleText data-test-id="enterCodeTitle">
        <Copy
          id={
            isReentry
              ? 'remoteActivation.reEnterCodeTitle'
              : 'remoteActivation.enterCodeTitle'
          }
        />
      </TitleText>
      <SubtitleText data-test-id="enterCodeSubtitle">
        <Copy
          id={
            isReentry
              ? 'remoteActivation.reEnterCodeSubtitle'
              : screenHint === 'Activation'
              ? 'remoteActivation.enterCodeOnDeviceSubtitle'
              : 'remoteActivation.pleaseConfirmCode'
          }
        />
      </SubtitleText>
      <Field name={FormField.UserCode}>
        {({
          form: { setFieldValue },
        }: {
          form: { setFieldValue: (field: string, value: any) => void };
        }) => (
          <CodeInput
            initialCode={initialValues.defaultValue}
            length={CODE_LENGTH}
            handleCodeUpdate={(newCode: string) =>
              setFieldValue(FormField.UserCode, newCode)
            }
          />
        )}
      </Field>
      <SubmitContainer>
        <SubmitButton
          data-test-id="startActivationButton"
          isSubmitting={isSubmitting}
          isValid={values[FormField.UserCode].length === CODE_LENGTH}
          text={
            useCopy(
              screenHint === 'Activation'
                ? 'remoteActivation.enterCodeSubmit'
                : 'auth.logIn',
            ) as string
          }
        />
      </SubmitContainer>
    </StyledForm>
  );
};

const StyledForm = styled.form`
  padding: 0 ${spaces.xxxLarge}px;
`;

const SubtitleText = styled.div`
  ${subtitleText};
  margin-bottom: ${spaces.xxxLarge}px;
`;

const TitleText = styled.div`
  ${mobileContentOffset};
  ${titleText};
  margin-bottom: ${spaces.small}px;
`;

const SubmitContainer = styled.div`
  margin-top: ${spaces.huge}px;
`;

export default CodeInputView;
