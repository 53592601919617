import styled from 'styled-components';
import { media } from '@peloton/styles';
import { spaces } from '@engage/styles';
import { useCopy, Copy } from '../../copy/renderers';
import SubmitButton from '../buttons/SubmitButton';
import { subtitleText, titleText } from '../ui/presets';
import { useView } from '../ViewProvider';

const ActivationStartView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { setView } = useView();
  return (
    <ActivationStartContainer>
      <TitleText>
        <Copy id={'remoteActivation.welcomePelotonDeviceTitle'} />
      </TitleText>
      <SubtitleText>
        <Copy id={'remoteActivation.welcomeSubtitle'} />
      </SubtitleText>

      <ActivationStartButton
        isSubmitting={false}
        isValid={true}
        text={useCopy('remoteActivation.getStartedLabel') as string}
        onClick={() => setView('UserSelect')}
      />
    </ActivationStartContainer>
  );
};

const ActivationStartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
`;

const TitleText = styled.div`
  ${titleText};
  margin-bottom: ${spaces.small}px;
`;

const SubtitleText = styled.div`
  ${media.tablet`
    padding: 0;
    width: 480px;
  `}
  padding: 0 ${spaces.small}px;
  ${subtitleText};
  margin-bottom: ${spaces.huge}px;
`;

const ActivationStartButton = styled(SubmitButton)`
  width: 280px;
  display: block;
  margin: auto;
`;

export default ActivationStartView;
