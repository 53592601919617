const ASSET_PREFIX = process.env.ASSET_PREFIX ?? '';

type AssetPathConfig = {
  assetPrefix?: string;
};

export const toAssetPath = (
  path: string,
  { assetPrefix = ASSET_PREFIX }: AssetPathConfig = {},
) => {
  if (assetPrefix !== '') {
    // drop trailing slash from asset prefix and leading slash from path, the join (ensuring exactly one "/")
    return [assetPrefix.replace(/\/$/, ''), path.replace(/^\//, '')].join('/');
  }

  return path;
};

export const toEnvVariables = () => {
  return {
    API_ENV: process.env.API_ENV ?? '',
    BRANCH: process.env.BRANCH ?? '',
    COMMIT_REF: process.env.COMMIT_REF ?? '',
    ENVIRONMENT: process.env.ENVIRONMENT ?? '',
  };
};
