import { Locale } from '@peloton/internationalize';

export type Entry = {
  key: string;
  value: string;
  __typename?: string;
};

export type CopyFile = {
  [collection: string]: {
    [copy: string]: string;
  };
};

export enum SupportedLocale {
  EnglishUnitedStates = Locale.EnglishUnitedStates,
  EnglishCanada = Locale.EnglishCanada,
  EnglishAustralia = Locale.EnglishAustralia,
  EnglishUnitedKingdom = Locale.EnglishUnitedKingdom,
  GermanGermany = Locale.GermanGermany,
  SpanishMexico = Locale.SpanishMexico,
  Default = Locale.Default,
}
