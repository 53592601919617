import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AuthEnv } from '@peloton/auth/authClient';
import { answerEmailVerificationChallenge } from '@engage/api-v2';
import { spaces } from '@engage/styles';
import { Copy } from '../../copy/renderers';
import { toEnvVariables } from '../../env/env';
import { engageApi } from '../auth/singletons';
import LoadingSpinner from '../loading/LoadingSpinner';
import { titleText } from '../ui/presets';

type ValidationState = 'validating' | 'error' | 'invalid' | 'success';

const EmailVerificationView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { query, push } = useRouter();
  const isProd = toEnvVariables().ENVIRONMENT === 'PRODUCTION';
  const authEnv = isProd ? AuthEnv.Prod : AuthEnv.Stage;
  const [validationState, setValidationState] = useState<ValidationState>('invalid');
  const verificationCode = query.verification;
  const email = query.email;

  if (verificationCode && email && validationState === 'invalid')
    setValidationState('validating');

  const validationStates = {
    validating: <LoadingSpinner size={40} isLoading={true} />,
    invalid: (
      <ValidationText>
        <Copy id={'emailValidationPage.invalid'} />
      </ValidationText>
    ),
  };

  const titleStates = {
    success: <Copy id={'emailValidationPage.titleSuccessful'} />,
    error: <Copy id={'emailValidationPage.titleUnsuccessful'} />,
  };

  useEffect(() => {
    if (validationState === 'validating') {
      answerEmailVerificationChallenge(engageApi(authEnv), {
        email: email as string,
        verification: verificationCode as string,
      })
        .then(() => {
          push(
            window.location.origin +
              window.location.pathname +
              '/verification-status/?result=success&email=' +
              email,
          );
        })
        .catch(() => {
          push(
            window.location.origin +
              window.location.pathname +
              '/verification-status/?result=error&email=' +
              email,
          );
        });
    }
  }, [validationState, verificationCode, authEnv, email, push]);

  return (
    <EmailVerificationContainer>
      <TitleText>
        <h1>{titleStates[validationState] || ''}</h1>
      </TitleText>
      {validationStates[validationState]}
    </EmailVerificationContainer>
  );
};

export default EmailVerificationView;

const ValidationText = styled.h1`
  color: white;
  text-align: center;
`;

const EmailVerificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
`;

const TitleText = styled.div`
  ${titleText};
  margin-bottom: ${spaces.small}px;
`;
