import { transparentize } from 'polished';
import type { FC } from 'react';
import React, { useState } from 'react';
import styled from 'styled-components';
import { toCloudinaryUrl } from '@engage/cloudinary';
import { black, gray3 } from '@engage/colors';
import { If } from '@engage/conditional-render';

const HEIGHT = 120;
const WIDTH = 120;

const StyledAvatar = styled.img`
  box-shadow: 0 0 3px ${transparentize(0.8, black)};
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
  border-radius: 50%;
`;

const avatarTransformations = {
  height: HEIGHT,
  width: WIDTH,
};

const AvatarPlaceholder = styled.div`
  height: ${HEIGHT}px;
  width: ${WIDTH}px;
  border-radius: 50%;
  box-shadow: 0 0 3px ${transparentize(0.8, black)};
  background-color: ${gray3};
`;

type Props = {
  imageUrl: string | undefined;
  username: string | undefined;
};

export const Avatar: FC<React.PropsWithChildren<Props>> = ({
  imageUrl,
  username,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(true);

  if (!username) {
    return <></>;
  }

  return (
    <>
      <If condition={isLoading}>
        <AvatarPlaceholder />
      </If>
      {imageUrl && (
        <StyledAvatar
          style={isLoading ? { display: 'none' } : {}}
          src={toCloudinaryUrl(imageUrl, avatarTransformations)}
          srcSet={toCloudinaryUrl(imageUrl, avatarTransformations)}
          alt={`Avatar for ${username}`}
          onLoad={() => setIsLoading(false)}
          {...props}
        />
      )}
    </>
  );
};
