import type { AuthEnv } from '@peloton/auth/authClient';
import type { AuthAction } from '@engage/auth/oauthFragment';

type AuthData = {
  audience: string;
  clientId: string;
  scope: string;
  userCode: string;
  pelotonPlatform: string;
  action: AuthAction | null;
};

const createAuthPersistKey = (authEnv: AuthEnv) => `auth/${authEnv}`;

export const clearAuthData = (authEnv: AuthEnv) => {
  if (!!window) {
    const authPersistKey = createAuthPersistKey(authEnv);

    window.localStorage.removeItem(`${authPersistKey}/audience`);
    window.localStorage.removeItem(`${authPersistKey}/clientId`);
    window.localStorage.removeItem(`${authPersistKey}/scope`);
    window.localStorage.removeItem(`${authPersistKey}/userCode`);
    window.localStorage.removeItem(`${authPersistKey}/pelotonPlatform`);
    window.localStorage.removeItem(`${authPersistKey}/action`);
  }
};

export const persistAuthData = (
  authEnv: AuthEnv,
  { audience, clientId, scope, userCode, pelotonPlatform, action }: AuthData,
) => {
  if (!!window) {
    const authPersistKey = createAuthPersistKey(authEnv);

    window.localStorage.setItem(`${authPersistKey}/audience`, audience);
    window.localStorage.setItem(`${authPersistKey}/clientId`, clientId);
    window.localStorage.setItem(`${authPersistKey}/scope`, scope);
    window.localStorage.setItem(`${authPersistKey}/userCode`, userCode);
    window.localStorage.setItem(`${authPersistKey}/pelotonPlatform`, pelotonPlatform);
    if (!!action) {
      window.localStorage.setItem(`${authPersistKey}/action`, action);
    }
  }
};

export const getPersistedAuthData = (authEnv: AuthEnv): AuthData => {
  if (!!window) {
    const authPersistKey = createAuthPersistKey(authEnv);

    const audience = window.localStorage.getItem(`${authPersistKey}/audience`) ?? '';
    const clientId = window.localStorage.getItem(`${authPersistKey}/clientId`) ?? '';
    const scope = window.localStorage.getItem(`${authPersistKey}/scope`) ?? '';
    const userCode = window.localStorage.getItem(`${authPersistKey}/userCode`) ?? '';
    const pelotonPlatform =
      window.localStorage.getItem(`${authPersistKey}/pelotonPlatform`) ?? '';
    const action = window.localStorage.getItem(`${authPersistKey}/action`) as AuthAction;

    return {
      audience,
      clientId,
      scope,
      userCode,
      pelotonPlatform,
      action,
    };
  }

  return {
    audience: '',
    clientId: '',
    scope: '',
    userCode: '',
    pelotonPlatform: '',
    action: null,
  };
};
