import React, { useEffect, useCallback, useRef, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { media, hoverTransition } from '@peloton/styles';
import { paleYellow, slate3 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { label4Large, label5Large } from '@engage/typography';
import { Cross } from './icons';

const Toast: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  hide = false,
  afterClose,
  icon,
  onClose,
  autoDismiss,
  children,
}) => {
  const container = useRef<HTMLDivElement>((null as any) as HTMLDivElement);

  const { closeState, handleClose } = useCloseHandlers({
    afterClose,
    autoDismiss,
    container,
    hide,
    onClose,
  });

  return (
    <StyledToast className={className} closeState={closeState} ref={container}>
      <StyledContent>
        <StyledMessageAndIcon>
          {icon ? <IconContainer>{icon}</IconContainer> : null}
          <ToastText role="alert" data-test-id="toastMessage">
            {children}
          </ToastText>
        </StyledMessageAndIcon>
        <CrossContainer
          type="button"
          data-test-id="closeToastButton"
          disabled={closeState === CloseState.Closed}
          onClick={handleClose}
        >
          <StyledCross />
        </CrossContainer>
      </StyledContent>
    </StyledToast>
  );
};

enum CloseState {
  Open = 'open',
  Closing = 'closing',
  Closed = 'closed',
}

const useAfterAnimation = (container: HTMLElement | undefined) =>
  useCallback(
    (cb: (() => unknown) | undefined) => {
      if (container && cb) {
        container.addEventListener('animationend', cb, { once: true });
      }
    },
    [container],
  );

const useCloseHandlers = ({
  afterClose,
  autoDismiss,
  container,
  hide = false,
  onClose,
}: UseCloseHandlersProps & {
  container: React.MutableRefObject<HTMLDivElement | undefined>;
}) => {
  const unmounted = useRef(false);
  const [closeState, setCloseState] = useState(
    hide ? CloseState.Closed : CloseState.Open,
  );

  const afterAnimation = useAfterAnimation(container.current);

  useEffect(
    () => () => {
      unmounted.current = true;
    },
    [],
  );

  const safeSetCloseState = (newCloseState: CloseState) => {
    if (unmounted.current) {
      return;
    }
    setCloseState(newCloseState);
  };

  const handleClose = useCallback(() => {
    safeSetCloseState(CloseState.Closing);
    if (onClose) {
      onClose();
    }
    afterAnimation(() => {
      safeSetCloseState(CloseState.Closed);
      if (afterClose) {
        afterClose();
      }
    });
  }, [afterAnimation]);

  useEffect(() => {
    const autoDismissToastDuration = 3000;
    if (autoDismiss && !hide) {
      setTimeout(handleClose, autoDismissToastDuration);
    }
  }, [autoDismiss, hide]);

  useEffect(() => {
    if (hide && closeState === CloseState.Open) {
      handleClose();
    } else if (!hide && closeState !== CloseState.Open) {
      safeSetCloseState(CloseState.Open);
    }
  }, [hide]);

  return { closeState, handleClose };
};

const SlideInFromAbove = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
`;

const Fadeout = keyframes`
  0% {
    opacity: 1;
    height: initial;
  }
  33% {
    opacity: 66%;
  }
  66% {
    opacity: 33%;
  }
  100% {
    opacity: 0;
    height: 0;
  }
`;

const toCloseStateStyle = (closeState: CloseState) => {
  switch (closeState) {
    case CloseState.Open:
      return css`
        animation: ${SlideInFromAbove} 0.4s ease;
      `;
    case CloseState.Closing:
      return css`
        animation: ${Fadeout} 0.4s ease both;
      `;
    case CloseState.Closed:
    default:
      return css`
        opacity: 0;
        visibility: hidden;
      `;
  }
};

const StyledToast = styled.div<{ closeState: CloseState }>`
  width: 360px;
  position: absolute;
  top: ${spaces.large}px;
  margin: 0 auto;
  overflow: hidden;
  background-color: ${paleYellow};
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  z-index: 4;
  ${media.tablet`
    border-radius: ${spaces.xxxxSmall}px;
  `}
  ${({ closeState }) => toCloseStateStyle(closeState)}
`;

const CrossContainer = styled.button`
  display: flex;
  align-items: center;
`;

const StyledCross = styled(Cross)`
  fill: ${slate3};
  height: 10px;
  min-height: 10px;
  width: 10px;
  min-width: 10px;
  opacity: 1;
  ${media.tablet`
    opacity: 0.5;
    ${hoverTransition({
      property: 'opacity',
      to: '1',
    })}
  `}
`;

const StyledContent = styled.div`
  text-align: initial; // Don't inherit alignment from parent
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  ${media.tablet`
    padding: 0 15px;
    ${hoverTransition({
      property: 'background',
      to: 'rgba(0, 0, 0, 0.05)',
    })}
    &:hover {
      ${StyledCross} {
        opacity: 1;
      }
    }
  `}
`;

const StyledMessageAndIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding: ${spaces.large}px 0;
`;

const IconContainer = styled.div`
  margin-right: 10px;
`;

const ToastText = styled.span`
  display: flex;
  color: ${slate3};
  align-items: center;
  ${label5Large};
  min-height: ${spaces.large}px;

  ${media.tablet`
     ${label4Large};
     min-height: ${spaces.large}px;
  `}
`;

type Props = {
  autoDismiss?: boolean;
  className?: string;
  afterClose?: () => void;
  icon?: JSX.Element;
  hide?: boolean;
  /**
   * @deprecated prefer afterClose
   *
   * Using onClose can cause issues like https://pelotoncycle.atlassian.net/browse/WE-2996
   */
  onClose?: () => void;
};

type UseCloseHandlersProps = Pick<
  Props,
  'hide' | 'autoDismiss' | 'afterClose' | 'onClose'
>;

export default Toast;
