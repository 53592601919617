import type { Auth0Client, IdToken } from '@auth0/auth0-spa-js';
import { datadogRum } from '@datadog/browser-rum';
import { useRouter } from 'next/router';
import { createContext, useContext, useState, useEffect } from 'react';
import { identify } from '@peloton/analytics';
import authClient, { AuthEnv } from '@peloton/auth/authClient';
import { getAuth0Client, loginErrors } from '@peloton/auth/OauthProvider';
import { SupportedTLD, toMatchingTld } from '@peloton/internationalize';
import { logoutPost } from '@engage/api-v2';
import { getLocalizedUrl } from '@engage/api-v2/generated/services/LocalesService';
import {
  claimRemoteDeviceActivation,
  claimRemoteAddSharedUser,
  getRemoteAddSharedUser,
  cancelRemoteAddSharedUser,
  updateRemoteAddSharedUserUserOnboardingEnded,
} from '@engage/api-v2/generated/services/UsersService';
import { LoginPage } from '@engage/auth/oauthFragment';
import { AnalyticsEvent } from '../../analytics';
import { useLocale } from '../../copy/context';
import { toEnvVariables } from '../../env/env';
import { useTrackingCallback } from '../../hooks';
import pollingWithType from '../../utils/pollingWithType';
import { useView } from '../ViewProvider';
import { persistAuthData, getPersistedAuthData, clearAuthData } from './persistAuthData';
import { engageApi } from './singletons';

const SWITCH_USERS_PARAM = 'switchUsers';
const AUTH_PROD_DOMAIN = 'auth.onepeloton.com';
const AUTH_STAGE_DOMAIN = 'auth-stage.onepeloton.com';
const IDENTITY_PROD_DOMAIN = 'identity.onepeloton.com';
const IDENTITY_STAGE_DOMAIN = 'identity-stage.onepeloton.com';
const userIdKey = 'http://onepeloton.com/user_id'; // this comes from auth0 IdToken
const POLLING_INTERVAL = 5000;

type Context = {
  authEnv: AuthEnv;
  activateDevice: () => Promise<void>;
  verifyDevice: (userCode: string) => Promise<void>;
  changeUsers: () => Promise<void>;
  user: IdToken | null;
  remoteActivationId: string;
  sessionTLD: SupportedTLD;
  logoutLegacySession: () => void;
};

const AuthContext = createContext<Context>({
  authEnv: AuthEnv.Stage,
  activateDevice: () => new Promise(() => null),
  verifyDevice: () => new Promise(() => null),
  changeUsers: () => new Promise(() => null),
  user: null,
  remoteActivationId: '',
  sessionTLD: SupportedTLD.Com,
  logoutLegacySession: () => {},
});

export const useAuth = () => useContext(AuthContext);

const createOauthClient = ({
  audience,
  clientId,
  scope,
  domain,
  redirectUri,
  caller,
}: {
  audience: string;
  clientId: string;
  scope: string;
  domain: string;
  redirectUri: string;
  caller: string;
}) => {
  if (!clientId) {
    datadogRum.addAction('missing client_id', {
      caller: caller,
    });
  }

  return getAuth0Client({
    clientId,
    domain,
    authorizationParams: {
      audience,
      scope,
      redirect_uri: redirectUri,
    },
    useRefreshTokens: false,
    cacheLocation: 'memory',
  });
};

let userCodeStateInterval: NodeJS.Timer | null;

const isProd = toEnvVariables().ENVIRONMENT === 'PRODUCTION';
export const domain = isProd ? AUTH_PROD_DOMAIN : AUTH_STAGE_DOMAIN;
export const identityDomain = isProd ? IDENTITY_PROD_DOMAIN : IDENTITY_STAGE_DOMAIN;
const authEnv = isProd ? AuthEnv.Prod : AuthEnv.Stage;

const engageApiClient = engageApi(authEnv);

const OauthProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const locale = useLocale();
  const [redirectUri, setRedirectUri] = useState('');
  const [oauthClient, setOauthClient] = useState<Auth0Client | null>(null);
  const client = authClient(authEnv, SupportedTLD.Com, { 'Peloton-Platform': 'web' });
  const router = useRouter();
  const [user, setUser] = useState<IdToken | null>(null);

  const [remoteActivationId, setRemoteActivationId] = useState('');
  const [sessionTLD, setSessionTLD] = useState(SupportedTLD.Com);

  const { setView } = useView();
  const { track } = useTrackingCallback();

  const cancelDeviceFlow = async (uCode?: string) => {
    const { userCode } = getPersistedAuthData(authEnv);

    try {
      setView('Loading');
      await client.post('/oauth/device/cancel', { user_code: uCode ?? userCode });
      clearAuthData(authEnv);
      setView('ActivationCancelled');
    } catch (e) {
      console.log('Error: set activation cancelled view', e.message);
      setView('ActivationCancelled');
    }
  };

  const getAndSetUser = async (newAuthClient: Auth0Client, uCode?: string) => {
    try {
      const tokenClaims = await newAuthClient.getIdTokenClaims();

      if (!tokenClaims) {
        throw new Error('No user token claims');
      }

      setUser(tokenClaims);
      trackMemberLoggedIn(uCode);
      return tokenClaims;
    } catch (e) {
      console.log('Error: canceling device flow', e.message);
      cancelDeviceFlow(uCode);
    }
    return null;
  };

  const checkForLoginErrors = async (redirect_uri: string) => {
    if (!!window) {
      const queryParams = new URLSearchParams(window.location.search);
      const errorMessage = queryParams.get('error_description');
      const match = loginErrors.exec(errorMessage ?? '');
      if (!!match) {
        const [code] = match;
        setView('Loading');
        // we've run into an error and must perform a global logout
        const tld = SupportedTLD.Com;
        const returnTo = `${authEnv}${tld}/sso/global_logout?continue=${encodeURIComponent(
          `${redirect_uri}`,
        )}?errorMessage=${code}`;

        const { audience, clientId, scope, userCode: uCode } = getPersistedAuthData(
          authEnv,
        );

        try {
          await client.post('/oauth/device/cancel', { user_code: uCode });
        } catch (e) {
          console.log('Error: canceling user code', e.message);
        }

        const newAuthClient = createOauthClient({
          audience,
          clientId,
          scope,
          redirectUri: redirect_uri,
          domain,
          caller: 'checkForLoginErrors',
        });

        newAuthClient?.logout({
          logoutParams: {
            returnTo,
          },
        });
      }
    }
  };

  useEffect(() => {
    const redirect_uri = `${window.location.origin}${window.location.pathname}`;
    setRedirectUri(redirect_uri);

    checkForLoginErrors(redirect_uri);

    // on return from universal logout, the auth client needs to be reinstantiated
    if (
      !!window &&
      window?.location.search.includes(
        `?${SWITCH_USERS_PARAM}` || `&${SWITCH_USERS_PARAM}`,
      )
    ) {
      setView('Loading');
      const { audience, clientId, scope, action: newAction } = getPersistedAuthData(
        authEnv,
      );
      const newAuthClient = createOauthClient({
        audience,
        clientId,
        scope,
        redirectUri: redirect_uri,
        domain,
        caller: 'switchUsers',
      });

      newAuthClient?.loginWithRedirect({
        authorizationParams: {
          redirect_uri,
          prompt: 'login',
        },
        fragment: JSON.stringify({
          type: newAction === 'login' ? LoginPage.Standard : LoginPage.Activation,
          action: newAction,
          message: '',
          locale,
        }),
        appState: { shouldActivateDevice: true },
      });
    } else if (!!window && window?.location.search.includes('?code' || '&code')) {
      // on return from universal login page, the auth client needs to be reinstantiated
      setView('Loading');
      const { audience, clientId, scope, userCode: uCode } = getPersistedAuthData(
        authEnv,
      );

      pollUserCodeState(uCode);
      const newAuthClient = createOauthClient({
        audience,
        clientId,
        scope,
        redirectUri: redirect_uri,
        domain,
        caller: 'code',
      });

      setOauthClient(newAuthClient);

      (async () => {
        await newAuthClient.handleRedirectCallback();

        router.replace(window.location.pathname, undefined, { shallow: true });
        getAndSetUser(newAuthClient, uCode);

        setView('UserSelect');
      })();
    } else {
      setView('CodeInput');
    }
  }, []);

  const trackMemberLoggedIn = (activationCode?: string) => {
    track(AnalyticsEvent.MemberIsLoggedIn, {
      activationCode,
    });
  };

  const changeUsers = async () => {
    // first perform a global logout which will redirect the user (this will log the user out of account app as well)
    // upon return, we call loginWithRedirect
    const tld = SupportedTLD.Com;
    const returnTo = `${authEnv}${tld}/sso/global_logout?continue=${encodeURIComponent(
      `${redirectUri}?${SWITCH_USERS_PARAM}=true`,
    )}`;

    await oauthClient?.logout({ logoutParams: { returnTo } });
  };

  const pollUserCodeState = (uCode: string) => {
    userCodeStateInterval = setInterval(async () => {
      try {
        const { data } = await client.post('/oauth/device/user_code/state', {
          user_code: uCode,
        });
        if (data.user_code_state === 'cancelled') {
          setView('ActivationCancelled');
          if (userCodeStateInterval) {
            clearInterval((userCodeStateInterval as unknown) as number);
          }
          clearAuthData(authEnv);
        }
      } catch (e) {
        console.log('Error: set activation cancelled view', e.message);
        setView('ActivationCancelled');
        if (userCodeStateInterval) {
          clearInterval((userCodeStateInterval as unknown) as number);
        }
        clearAuthData(authEnv);
      }
    }, POLLING_INTERVAL);
  };

  const verifyDevice = async (uCode: string): Promise<void> => {
    const { data } = await client.post('/oauth/device/verify', {
      user_code: uCode,
    });

    pollUserCodeState(uCode);
    persistAuthData(authEnv, { ...data, userCode: uCode });

    track(AnalyticsEvent.EnteredActivationCode, {
      activationCode: uCode,
      actionType: data.action,
    });

    const newAuthClient = createOauthClient({
      audience: data.audience,
      clientId: data.clientId,
      scope: data.scope,
      redirectUri: redirectUri,
      domain,
      caller: 'verifyDevice',
    });

    setOauthClient(newAuthClient);

    // this will redirect to auth0 to check if member is logged in
    // if the member is logged in, they will automatically get redirected back to the activation app without intervention
    // if they are not logged in, it will show them the login page
    await newAuthClient.loginWithRedirect({
      authorizationParams: {
        redirect_uri: redirectUri,
      },
      fragment: JSON.stringify({
        type: data.action === 'login' ? LoginPage.Standard : LoginPage.Activation,
        prompt: 'none',
        action: data.action,
        message: '',
        locale,
      }),
      appState: { shouldActivateDevice: false },
    });
  };

  const activateDevice = async (maybeAuthClient?: Auth0Client) => {
    const { userCode, action } = getPersistedAuthData(authEnv);

    try {
      setView('Loading');
      const newAuthClient = maybeAuthClient || oauthClient;
      const token = await newAuthClient?.getTokenSilently({ cacheMode: 'off' });
      if (!token) {
        throw new Error('No user token');
      }

      const userId = user?.[userIdKey] ?? '';
      const newUser = !user?.preferred_username;

      try {
        if (userId) {
          identify({ userId });
        }
      } catch (e) {
        console.log('Error: segment identify', e.message);
      }

      await client.post(
        '/oauth/device/activate',
        { user_code: userCode },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (action === 'device_activation') {
        try {
          const localizedResponse = await getLocalizedUrl(
            engageApiClient,
            {
              url: 'https://members.onepeloton.com',
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );
          setSessionTLD(toMatchingTld(localizedResponse.data.url));
        } catch (e) {
          // TODO: remove console log and instead log to DD or new relic
          console.log('error getting localized url, fallback to .com', e);
        }

        const response = await pollingWithType(
          () =>
            claimRemoteDeviceActivation(engageApiClient, userId, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }),
          res => res.data.isClaimed ?? false,
          err => {
            if (err.response?.status && err.response?.status === 404) {
              return true;
            } else {
              if (userCodeStateInterval) {
                clearInterval((userCodeStateInterval as unknown) as number);
              }
              // we don't have a remoteDeviceActivationId so we aren't calling cancel here
              clearAuthData(authEnv);
              setView('ActivationCancelled');
              return false;
            }
          },
        );

        setRemoteActivationId(response?.data.id ?? '');

        track(AnalyticsEvent.ActivatedDeviceFlow, {
          activationCode: userCode,
          actionType: action,
          platformType: response?.data.platform,
          productModel: response?.data.productModel,
          deviceId: response?.data.deviceId,
          userId,
        });

        setView('PageRedirect');
      } else if (action === 'add_account') {
        const response = await pollingWithType(
          () =>
            claimRemoteAddSharedUser(engageApiClient, userId, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }),
          res => res.data.isClaimed ?? false,
          err => {
            if (err.response?.status && err.response?.status === 404) {
              return true;
            } else {
              // we don't have a remoteAddSharedUserId so we aren't calling cancel here
              clearAuthData(authEnv);
              setView('ActivationCancelled');
              return false;
            }
          },
        );
        const remoteAddSharedUserId = response?.data.id ?? '';
        setRemoteActivationId(remoteAddSharedUserId);
        // Once we claim, we are waiting on Tiger to also claim said
        // user on the device, and we're done.
        await pollingWithType(
          () =>
            getRemoteAddSharedUser(engageApiClient, userId, remoteAddSharedUserId, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }),
          res => res.data.hasSharedUserBeenAdded ?? false,
          err => {
            cancelRemoteAddSharedUser(
              engageApiClient,
              userId,
              remoteAddSharedUserId,
              undefined,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            );
            clearAuthData(authEnv);
            setView('ActivationCancelled');
            return false;
          },
        );
        if (!newUser) {
          await updateRemoteAddSharedUserUserOnboardingEnded(
            engageApiClient,
            userId,
            remoteAddSharedUserId,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );
        }
        if (userCodeStateInterval) {
          clearInterval((userCodeStateInterval as unknown) as number);
        }

        track(AnalyticsEvent.ActivatedDeviceFlow, {
          activationCode: userCode,
          actionType: action,
        });

        setView(newUser ? 'PageRedirect' : 'ActivationComplete');
      } else {
        track(AnalyticsEvent.ActivatedDeviceFlow, {
          activationCode: userCode,
          actionType: action,
        });

        clearAuthData(authEnv);
        if (userCodeStateInterval) {
          clearInterval((userCodeStateInterval as unknown) as number);
        }
        setView('ActivationComplete');
      }
      newAuthClient?.logout({ openUrl: false });
    } catch (e) {
      console.log('Error: canceling device flow', e.message);
      cancelDeviceFlow(userCode);
    }
  };

  // Some applications will prefer legacy session auth, overriding the Oauth login that occurs as part of device activation, causing issues.
  const logoutLegacySession = () => {
    logoutPost(engageApiClient, 'web');
  };

  return (
    <AuthContext.Provider
      value={{
        authEnv,
        activateDevice,
        verifyDevice,
        changeUsers,
        user,
        remoteActivationId,
        sessionTLD,
        logoutLegacySession,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default OauthProvider;
