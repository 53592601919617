import React from 'react';
import styled from 'styled-components';
import { media, mobileLandscape, BreakpointWidth } from '@peloton/styles';
import { white, gray4 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { Footer } from '../footer';
import { useError, Oops } from '../Oops';
import DotsBackground from './DotsBackground';
import PelotonLogo from './PelotonLogo';

interface DarkLayoutProps {
  children: React.ReactNode;
  removeBottomMargin?: boolean;
}

const DarkLayout: React.FC<DarkLayoutProps> = ({ children, removeBottomMargin }) => {
  const { shouldShowOopsPage } = useError();

  if (shouldShowOopsPage) {
    return <Oops />;
  }

  return (
    <DotsBackground>
      <div className="container">
        <div className="logo-container">
          <StyledPelotonLogo />
        </div>
        {children}
      </div>
      <StyledFooter darkTheme />
      <style jsx>{`
        .container {
          max-width: 620px;
          width: 100%;
          display: flex;
          align-self: center;
          flex-direction: column;
        }

        .logo-container {
          display: flex;
          align-self: center;
          margin-top: ${spaces.large}px;
          margin-bottom: 50px;
        }

        @media (min-width: ${BreakpointWidth.tablet}px) {
          .logo-container {
            margin-top: ${spaces.huge}px;
            margin-bottom: ${removeBottomMargin ? '50px' : '120px'};
          }
        }

        @media only screen and (min-width: 320px) and (max-width: 824px) and (orientation: landscape) {
          .logo-cotainer {
            margin-top: ${spaces.large}px;
            margin-bottom: 50px;
          }
        }

        .centered-block {
          text-align: center;
          margin-top: ${spaces.large}px;
        }

        .sign-up-text {
          margin-top: 30px;
          font-size: 12px;
          font-weight: 400;
          color: ${gray4};
        }
      `}</style>
    </DotsBackground>
  );
};

const StyledPelotonLogo = styled(PelotonLogo)`
  height: 30px;
  width: 100px;
  fill: ${white};
  ${media.tablet`
    height: 40px;
    width: 135px;
  `};
  ${mobileLandscape`
    height: 30px;
    width: 100px;
  `}
`;

const StyledFooter = styled(Footer)`
  flex-shrink: 0;
`;

export default DarkLayout;
