import React from 'react';
import styled from 'styled-components';
import { spaces } from '@engage/styles';
import { Copy } from '../../copy/renderers';
import { useAuth } from '../auth/OauthProvider';
import { Avatar } from '../ui/Avatar';
import { smallTitleText, StyledOnboardingButton, linkStyle } from '../ui/presets';

const UserSelectView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { user, activateDevice, changeUsers } = useAuth();

  return (
    <UserSelectContainer>
      <AvatarContainer>
        <Avatar
          imageUrl={user?.picture}
          username={user?.preferred_username || user?.name}
        />
      </AvatarContainer>

      <UserName data-test-id="accountUserName">
        {user?.preferred_username || user?.name}
      </UserName>

      <StyledOnboardingButton
        data-test-id="continueButton"
        onClick={() => activateDevice()}
      >
        <Copy id="remoteActivation.continueLabel" />
      </StyledOnboardingButton>
      <SkipLink data-test-id="useDifferentAccountLink" onClick={changeUsers}>
        <Copy id="remoteActivation.switchUsers" />
      </SkipLink>
    </UserSelectContainer>
  );
};

const UserSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AvatarContainer = styled.div`
  margin-bottom: ${spaces.small}px;
`;

const UserName = styled.div`
  ${smallTitleText};
  padding-bottom: ${spaces.xHuge}px;
`;

const SkipLink = styled.button`
  ${linkStyle};
  margin: auto;
  margin-top: ${spaces.xxxLarge}px;
`;

export default UserSelectView;
