import type { AxiosPromise, AxiosResponse, AxiosError } from 'axios';

const MAX_POLL_COUNT = 30;
const TIMEOUT_DURATION_MILLIS = 1000;
export const POLL_TOO_LONG_ERR = 'Waited too long while polling';
const UNKNOWN_ERROR = 'UNKNOWN_ERROR';

/**
 * make sure that the error callback ALWAYS returns true or false
 * it's used to deal with sideeffects we wish to do (like redirecting
 * to cancel) and does not need to handle the error itself. We use a
 * try catch block around the pollingWithType to handle anything else with
 * the error.
 * @param pollingQuery
 * @param successCallback
 * @param successChecker
 * @param errorCallBack
 * @param maxPollCount
 * @param pollIntervalInMilli
 */
async function pollingWithType<T>(
  pollingQuery: () => AxiosPromise<T>,
  successChecker: (resp: AxiosResponse<T>) => boolean,
  errorCallBack: (err: AxiosError<T>) => boolean = () => false,
  maxPollCount: number = MAX_POLL_COUNT,
  pollIntervalInMilli: number = TIMEOUT_DURATION_MILLIS,
): Promise<AxiosResponse<T> | undefined> {
  let isPollingComplete = false;
  let pollCount = 0;

  while (!isPollingComplete) {
    try {
      if (pollCount >= maxPollCount) {
        throw POLL_TOO_LONG_ERR;
      }
      pollCount++;
      const response = await pollingQuery();
      if (successChecker(response)) {
        isPollingComplete = true;
        return Promise.resolve(response);
      }
    } catch (err) {
      const shouldSkip = errorCallBack(err);
      if (!shouldSkip) {
        isPollingComplete = true;
        return Promise.reject(err);
      }
    } finally {
      if (!isPollingComplete) {
        await new Promise(resolve => setTimeout(resolve, pollIntervalInMilli));
      }
    }
  }
  return Promise.reject(UNKNOWN_ERROR);
}

export default pollingWithType;
