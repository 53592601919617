import { BreakpointWidth } from '@peloton/styles';
import { spaces } from '@engage/styles';
import { b2, header4Large, header5Large } from '@engage/typography';
import { Copy } from '../../copy';

const Oops: React.FC<React.PropsWithChildren<unknown>> = () => (
  <div>
    <h2 className="oops-header">
      <Copy id="site.error" />
    </h2>
    <p className="oops-text">
      <Copy id="site.errorDetails" />
    </p>
    <style jsx>{`
      .oops-header {
        ${header5Large.join('')}
        margin: ${spaces.giant}px ${spaces.huge}px 0 ${spaces.huge}px;
        text-align: center;
      }

      .oops-text {
        ${b2.join('')}
        margin: ${spaces.large}px ${spaces.huge}px ${spaces.huge}px ${spaces.huge}px;
        text-align: center;
      }

      @media (min-width: ${BreakpointWidth.tablet}px) {
        .oops-header {
          ${header4Large.join('')}
        }

        .oops-text {
          margin-top: 30px;
        }
      }
    `}</style>
  </div>
);

export default Oops;
