import React, { useEffect, useRef } from 'react';
import { PRODUCTION } from '@peloton/app-config';
import { redirect } from '@peloton/navigation';
import { Copy } from '../../copy/renderers';
import { identityDomain } from '../auth/OauthProvider';
import { useError } from '../Oops/ErrorContext';
const POLLING_INTERVAL = 5000;

const getCheckVerificationPath = (email: string) =>
  `/auth/email/check_verification?email=${encodeURIComponent(email)}`;

type OpenErrorToastType = () => void;

export const checkEmailVerification = async (
  email: string,
  openErrorToast: OpenErrorToastType,
) => {
  try {
    const response = await fetch(
      `https://${identityDomain}${getCheckVerificationPath(email)}`,
    );

    let errorMessage;
    if (!response.ok) {
      errorMessage = await response.json();
      throw new Error(errorMessage);
    }

    return response.json();
  } catch (e) {
    openErrorToast();
    console.log(e);
    return;
  }
};

export const useEmailVerificationPolling = (email: string, redirectUrl?: string) => {
  const MEMBERS_PROFILE_URL = PRODUCTION
    ? 'https://members.onepeloton.com/'
    : 'https://members.test.onepeloton.com/';

  const timerIdRef = useRef(null);

  const { setErrorMessage } = useError();
  const defaultErrorMessage = <Copy id={'emailValidationPage.titleIVRUnsuccessful'} />;

  const startPolling = (pollingCb: () => void) => {
    // @ts-expect-error
    timerIdRef.current = setInterval(pollingCb, POLLING_INTERVAL);
  };
  const stopPolling = () => {
    // @ts-expect-error
    clearInterval(timerIdRef.current);
  };

  const openErrorToast = () => {
    setErrorMessage((defaultErrorMessage as unknown) as string);
  };
  const pollCheckEmailVerification = async () => {
    try {
      const response = await checkEmailVerification(email, openErrorToast);
      if (Boolean(response?.email_verified)) {
        redirect(redirectUrl || MEMBERS_PROFILE_URL);
        return;
      }
    } catch (e) {
      openErrorToast();
      console.log(e);
      return;
    }
  };

  useEffect(() => {
    startPolling(pollCheckEmailVerification);
    return () => stopPolling();
  }, [email]);
};
