import type { Location } from 'history';
import { Locale } from '@peloton/internationalize';
import { parseSearch } from '@peloton/redux';
import { TagModalView, TAGS_MODAL_NAME } from '@engage/tags';

export type AuthAction = 'device_activation' | 'add_account' | 'login';

export enum LoginPage {
  Activation = 'Activation',
  Social = 'Social',
  Standard = 'Standard',
  Tags = 'Tags',
  Register = 'Register',
}

const isTagsPath = (location: Location) => {
  const searchParams = parseSearch(location);

  return (
    searchParams.modal === TAGS_MODAL_NAME &&
    searchParams.tagsView === TagModalView.TagDetails
  );
};
const getTag = (location: Location) => {
  const searchParams = parseSearch(location);

  return searchParams.tagName ?? '';
};

export type OauthFragment =
  | {
      type: LoginPage.Activation;
      action: AuthAction;
      message: string;
      locale: Locale;
    }
  | {
      type: LoginPage.Tags;
      tag: string;
      message: string;
      locale: Locale;
      requesterEmail?: string;
    }
  | {
      type: LoginPage.Standard;
      message: string;
      locale: Locale;
      requesterEmail?: string;
    }
  | {
      type: LoginPage.Register;
      message: string;
      locale: Locale;
      origin: string;
      device: string;
    };

export const getOauthFragment = (
  location: Location,
  message: string = '',
  locale: Locale = Locale.EnglishUnitedStates,
  requesterEmail: string = '',
): OauthFragment => {
  if (isTagsPath(location)) {
    return {
      type: LoginPage.Tags,
      tag: getTag(location),
      message: '',
      locale,
      requesterEmail,
    };
  }

  return {
    type: LoginPage.Standard,
    message,
    locale,
    requesterEmail,
  };
};

export const safelyDecodeFragment = (fragment: string): OauthFragment => {
  try {
    return JSON.parse(window.decodeURIComponent(fragment));
  } catch (e) {
    return {
      type: LoginPage.Standard,
      message: '',
      locale: Locale.EnglishUnitedStates,
      requesterEmail: '',
    };
  }
};
