import styled, { keyframes } from 'styled-components';
import type { Props as StaticProps } from './StaticSpinner';
import StaticSpinner from './StaticSpinner';

type Props = {
  isLoading: boolean;
  size: number;
} & StaticProps;

const SpinnerWithSize: React.FC<React.PropsWithChildren<Props>> = ({
  size,
  isLoading,
  title = 'Loading',
  ...htmlProps
}) => (
  <>
    <StaticSpinner title={title} height={size} width={size} {...htmlProps} />
    <VisuallyHidden role="region" aria-live="polite">
      {title}
    </VisuallyHidden>
  </>
);

const VisuallyHidden = styled.div`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingSpinner = styled(SpinnerWithSize)`
  ${(props: Props) => (!props.isLoading ? 'display: none;' : '')}
  height: ${(props: Props) => props.size}px;
  width: ${(props: Props) => props.size}px;
  animation: ${spin} 1.6s infinite linear;
`;

export default LoadingSpinner;
