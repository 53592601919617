/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { I18nMetadataResponse } from '../../../api-v2-models/generated/I18nMetadataResponse';
import type { SupportedLocale } from '../../../api-v2-models/generated/SupportedLocale';
import { BaseAPI, globalImportUrl, AxiosPromise } from '../core/request';

/**
 * Return all supported locales
 * Return all supported locales
 * @returns SupportedLocale List of supported locales
 * @throws ApiError
 */
export const getSupportedLocales = (
    api: BaseAPI,
    options: any = {},
): AxiosPromise<Array<SupportedLocale>> => {
    const localVarPath = `/api/supported_locales`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get localized url
 * Take in specific US based url and localize based on current session locale or specified country code if provided. For non-supported locales, default to US localization.
 * @param requestBody
 * @returns any Successful response
 * @throws ApiError
 */
export const getLocalizedUrl = (
    api: BaseAPI,
    requestBody?: {
        /**
         * Country code, e.g. US,GB,CA,DE,AU
         */
        countryCode?: string,
        /**
         * Url to be localized.
         */
        url?: string,
    },
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/api/localized_url`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Return all supported locales and available countries
 * Returns supported locales and available countries. Countries are sorted alphabetically, with the exception of the country determined based on the current session, which will be returned first in the list.
 * @returns I18nMetadataResponse List of supported locales
 * @throws ApiError
 */
export const getI18NMetadata = (
    api: BaseAPI,
    options: any = {},
): AxiosPromise<I18nMetadataResponse> => {
    const localVarPath = `/api/i18n_metadata`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

