import { datadogRum } from '@datadog/browser-rum';

type EnvVariables = {
  COMMIT_REF: string;
  ENVIRONMENT: string;
};

export const init: (envVars: EnvVariables) => void = ({ COMMIT_REF, ENVIRONMENT }) => {
  datadogRum.init({
    applicationId: '77065cf2-1b9b-4455-91e8-15e2fd50f164',
    clientToken: 'pubc7e2f4daff63901a16ef79038dcba4f8',
    site: 'datadoghq.com',
    service: 'auth-relentless',
    // Specify a version number to identify the deployed version of your application in Datadog
    version: COMMIT_REF,
    env: ENVIRONMENT,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  datadogRum.startSessionReplayRecording();
};
