import { datadogRum } from '@datadog/browser-rum';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { createContext, useContext, useState, useEffect } from 'react';
import {
  GOOGLE_LOGIN_ERROR_CODE,
  APPLE_LOGIN_ERROR_CODE,
  USER_EXISTS_LOGIN_ERROR_CODE,
  USER_DOES_NOT_EXIST_LOGIN_ERROR_CODE,
} from '@peloton/auth/OauthProvider';
import { useCopy } from '../../copy/renderers';
import Toast from '../Toast';

const CONTEXT = createContext({
  shouldShowOopsPage: false,
  setShouldShowOopsPage: (b: boolean) => null as unknown,
  errorMessage: '',
  setErrorMessage: (errorMessage: string) => null as unknown,
});

export const useError = () => useContext(CONTEXT);

export const ErrorProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const googleErrorMessage = useCopy('auth.loggedInWithOtherMethodGoogle');
  const appleErrorMessage = useCopy('auth.loggedInWithOtherMethodApple');
  const userDoesNotExistError = useCopy('auth.userDoesNotExistError');
  const userExistsError = useCopy('auth.userExistsError');
  const defaultError = useCopy('auth.defaultError');

  const loginErrorMessageMap = {
    [GOOGLE_LOGIN_ERROR_CODE]: googleErrorMessage,
    [APPLE_LOGIN_ERROR_CODE]: appleErrorMessage,
    [USER_DOES_NOT_EXIST_LOGIN_ERROR_CODE]: userDoesNotExistError,
    [USER_EXISTS_LOGIN_ERROR_CODE]: userExistsError,
  };

  const router = useRouter();
  const [shouldShowOopsPage, setShouldShowOopsPage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!!window) {
      const queryParams = new URLSearchParams(window.location.search);
      const errorMessageFromParams = queryParams.get('errorMessage');

      if (!!errorMessageFromParams) {
        const errorMessageObj = new Error(errorMessageFromParams);
        errorMessageObj.name = 'ErrorToastShown';
        datadogRum.addError(errorMessageObj);

        setErrorMessage(loginErrorMessageMap[errorMessageFromParams] ?? defaultError);
        router.replace(window.location.pathname, undefined, { shallow: true });
      }
    }
  }, []);

  return (
    <CONTEXT.Provider
      value={{ shouldShowOopsPage, setShouldShowOopsPage, errorMessage, setErrorMessage }}
    >
      <div className="toast-container">
        <Toast hide={!errorMessage} onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Toast>
      </div>
      {children}
      <style jsx>{`
        .toast-container {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      `}</style>
    </CONTEXT.Provider>
  );
};
