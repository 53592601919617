import { css } from 'styled-components';

const bodyTextStyles = css`
  letter-spacing: 0.02em;
  line-height: 1.5em;
`;

/**
 * @deprecated prefer p1 in @engage/typography/font-styles
 *
 */
export const b1 = css`
  ${bodyTextStyles}
  font-size: 16px;
  font-weight: 300;
`;

/**
 * @deprecated prefer p2 in @engage/typography/font-styles
 *
 */
export const b2 = css`
  ${bodyTextStyles}
  font-size: 14px;
  font-weight: 300;
`;

/**
 * @deprecated prefer p3 in @engage/typography/font-styles
 *
 */
export const b3 = css`
  ${bodyTextStyles}
  font-size: 13px;
  font-weight: 300;
`;

/**
 * @deprecated prefer p4 in @engage/typography/font-styles
 *
 */
export const b4 = css`
  ${bodyTextStyles}
  font-size: 11px;
  font-weight: 300;
`;
