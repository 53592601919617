import { createContext, useContext, useState } from 'react';
import ActivationCancelledView from './ActivationCancelled/ActivationCancelledView';
import ActivationComplete from './ActivationComplete/ActivationCompleteView';
import ActivationStartView from './ActivationStart/ActivationStartView';
import CodeInputView from './CodeInput/CodeInputView';
import EmailVerificationView from './EmailVerification/EmailVerificationView';
import OnRegistrationView from './EmailVerification/OnRegistrationView';
import PageRedirectView from './PageRedirect/PageRedirectView';
import UnverifiedView from './Unverified/UnverifiedView';
import UserSelectView from './UserSelect/UserSelectView';

export type ScreenHint =
  | 'Logging_In'
  | 'Activation'
  | 'Email_Verification'
  | 'Email_Verification_On_Registration'
  | 'Unverified';

type View =
  | 'CodeInput'
  | 'UserSelect'
  | 'ActivationStart'
  | 'PageRedirect'
  | 'ActivationCancelled'
  | 'ActivationComplete'
  | 'EmailVerification'
  | 'Error'
  | 'Loading'
  | 'EmailVerificationOnRegistration'
  | 'Unverified';

type Context = {
  currentView: View;
  setView: (view: View) => void;
  screenHint: ScreenHint;
  ViewComponent: React.FC<React.PropsWithChildren<unknown>>;
};

const ViewContext = createContext<Context>({
  currentView: 'CodeInput',
  setView: v => 'Error',
  screenHint: 'Logging_In',
  ViewComponent: CodeInputView,
});

const getViewComponent = (view: View): Context['ViewComponent'] => {
  switch (view) {
    case 'CodeInput':
      return CodeInputView;

    case 'UserSelect':
      return UserSelectView;

    case 'ActivationStart':
      return ActivationStartView;

    case 'PageRedirect':
      return PageRedirectView;

    case 'ActivationCancelled':
      return ActivationCancelledView;

    case 'ActivationComplete':
      return ActivationComplete;

    case 'EmailVerification':
      return EmailVerificationView;

    case 'EmailVerificationOnRegistration':
      return OnRegistrationView;

    case 'Unverified':
      return UnverifiedView;

    default:
      return CodeInputView;
  }
};

export const useView = () => useContext(ViewContext);

const ViewProvider: React.FC<React.PropsWithChildren<{ screenHint: ScreenHint }>> = ({
  children,
  screenHint,
}) => {
  const [currentView, setView] = useState<View>('Loading');

  return (
    <ViewContext.Provider
      value={{
        screenHint,
        currentView,
        setView,
        ViewComponent: getViewComponent(currentView),
      }}
    >
      {children}
    </ViewContext.Provider>
  );
};

export default ViewProvider;
