import type { ExtLinkEnv } from '@peloton/external-links';
import { SupportedTLD } from '@peloton/internationalize';
import { SupportedLocale } from '../copy/models';

const getTLD = (locale: SupportedLocale) => {
  switch (locale) {
    case SupportedLocale.GermanGermany:
      return SupportedTLD.De;
    case SupportedLocale.EnglishUnitedKingdom:
      return SupportedTLD.CoUk;
    case SupportedLocale.EnglishCanada:
      return SupportedTLD.Ca;
    case SupportedLocale.EnglishAustralia:
      return SupportedTLD.Au;
    case SupportedLocale.EnglishUnitedStates:
    default:
      return SupportedTLD.Com;
  }
};

export const updateTLD = <T extends Pick<ExtLinkEnv, 'hostname'>>(
  env: T,
  locale: SupportedLocale,
  suppliedTLD?: SupportedTLD,
): T => {
  const tld = getTLD(locale);

  return {
    ...env,
    hostname: env.hostname.replace(/\.com/, suppliedTLD ?? tld),
  };
};
