import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { hoverTransition, defaultTransition, media } from '@peloton/styles';
import { gray3, gray4, gray5, white, slate2, superRedA11y } from '@engage/colors';
import { spaces } from '@engage/styles';
import {
  title28Bold,
  title24Bold,
  title20,
  title20Bold,
  label18,
  body12,
  interV,
  link3,
} from '@engage/typography';

export type ActionButtonProps = Partial<
  Pick<SubmitButtonProps, 'isSubmitting' | 'isValid'>
>;

export const titleText = css`
  ${media.tablet`
    ${title28Bold};
  `}
  ${title24Bold};
  color: ${white};
  text-align: center;
`;

export const smallTitleText = css`
  ${media.tablet`
    ${title24Bold};
  `}
  ${title20Bold};
  color: ${white};
  text-align: center;
`;

export const subtitleText = css`
  ${body12};
  color: ${gray3};
  text-align: center;
`;

export const largeSubtitle = css`
  ${media.tablet`
    ${title20};
  `}
  ${label18};
  color: ${gray4};
  text-align: center;
`;

export const mobileContentOffset = css`
  ${media.tablet`
    margin-top: 0px;
  `}
  margin-top: -4px;
`;

export const linkStyle = css`
  ${link3};
  color: ${white};
  font-size: ${spaces.small}px;
`;

export type SubmitButtonProps = {
  onClick?: (e: React.SyntheticEvent) => void;
  isSubmitting: boolean;
  text: string;
  isValid?: boolean;
  fill?: string;
  loaderSize?: number;
  StyledButton?: React.ComponentType<
    React.PropsWithChildren<
      ActionButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
    >
  >;
};

export const buttonText = css`
  text-align: center;
  font-family: ${interV};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

export const sharedButtonStyles = css`
  ${buttonText}
  border-radius: 50px;
  cursor: pointer;
  border: none;
`;

export const largeButtonStyles = css`
  height: ${spaces.xHuge}px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1px;
  border-radius: ${spaces.xLarge}px;
  min-width: 200px;
  padding: 0 30px ${spaces.tiny}px;
`;

export const darkDisabled = css`
  :disabled {
    background-color: ${transparentize(0.9, white)};
    color: ${gray5};
    cursor: auto;
  }
`;

export const darkStyle1 = css`
  color: ${slate2};
  background-color: ${white};
  opacity: 0.8;
  ${hoverTransition({
    property: 'opacity',
    to: '0.95',
  })}
  ${defaultTransition('filter, opacity')}
  &:active {
    filter: brightness(120%);
  }
  ${darkDisabled} :disabled {
    opacity: 1;
  }
`;

export const disabledStyles = (color: string) => (props: ActionButtonProps) =>
  props.isSubmitting
    ? `:disabled {
        background-color: ${color};
        opacity: 1;
    }`
    : '';

const DarkButton1Large = styled.button<ActionButtonProps>`
  ${sharedButtonStyles}
  ${largeButtonStyles}
  ${darkStyle1}
  ${disabledStyles(white)}
`;

export const StyledOnboardingButton = styled(DarkButton1Large)`
  width: 280px;
  display: block;
  margin: auto;
  background-color: ${superRedA11y};
  color: ${white};
  opacity: 1;
`;
